export const getColorForTable = (table, role, selectedPeople, selectedDate, selectedDuration, reservations) => {
  // Check if role is admin, then color is always gray
  if (role === 1)
    return 'gray.500'
  // Check if the table capacity is sufficient
  if (table.capacity < selectedPeople) {
    return 'red.500'; // Set the color to red when capacity is not sufficient
  } else {
    // Check if there are any overlapping reservations for this table
    const isTableAvailable = reservations.every((reservation) => {
      if (reservation.tableId === table.id) {
        const reservationStartTime = new Date(reservation.start);
        const reservationEndTime = new Date(reservation.start);
        reservationEndTime.setHours(reservationEndTime.getHours() + reservation.duration);

        const selectedStartTime = new Date(selectedDate);
        const selectedEndTime = new Date(selectedDate);
        selectedEndTime.setHours(selectedEndTime.getHours() + selectedDuration);

        // Check for reservation overlap
        return (
          selectedEndTime <= reservationStartTime || // No overlap (selected reservation ends before the other starts)
          selectedStartTime >= reservationEndTime // No overlap (selected reservation starts after the other ends)
        );
      }
      return true; // Not related to this table, so it doesn't affect availability
    });

    return isTableAvailable ? 'green.500' : 'red.500';
  }
};

export const valid = (table, toast) => {
  if (table.columnEnd === table.columnStart) {
    showErrorToast(toast, "A kezdőoszlop és végoszlop nem egyezhetnek meg!");
    return false;
  } else if (table.rowEnd === table.rowStart) {
    showErrorToast(toast, "A kezdősor és végsor nem egyezhetnek meg!");
    return false;
  } else if (table.columnStart < 1 || table.columnStart > 8) {
    showErrorToast(toast, "A kezdőoszlop 1 és 8 közötti értéket vehet fel.");
    return false;
  } else if (table.rowStart < 1 || table.rowStart > 8) {
    showErrorToast(toast, "A kezdősor 1 és 8 közötti értéket vehet fel.");
    return false;
  } else if (table.columnEnd < 2 || table.columnEnd > 9) {
    showErrorToast(toast, "A végoszlop 2 és 9 közötti értéket vehet fel.");
    return false;
  } else if (table.rowEnd < 2 || table.rowEnd > 9) {
    showErrorToast(toast, "A végsor 2 és 9 közötti értéket vehet fel.");
    return false;
  }
  return true;
};

export const showErrorToast = (toast, text = 'A művelet sikertelen volt! A felvett asztal fedi valamelyik meglévő asztalt!') => {
  toast({
    title: text,
    status: 'error',
    isClosable: true,
  })
}

export const showSuccessToast = (toast) => {
  toast({
    title: 'A művelet sikeres!',
    status: 'success',
    isClosable: true,
  });
}