export default class TableService{
    constructor(httpClient){
        this.httpClient = httpClient;
    }

    async getTables(){
        try{
            const response = await this.httpClient.get('Tables');
            return response.json();
        }catch(error){
            console.error(error)
        }
    }

    async updateTable(table) {
        try {
            const response = await this.httpClient.put('Tables', table);
    
            if (response.status === 200) {
                return true;
            } else {
                return false;
            }
        } catch (error) {
            console.error(error);
        }
    }

    async createTable(newTable) {
        try {
            const response = await this.httpClient.post('Tables', newTable);

            if (response.status === 200) {
                console.log(response.data)
                return response.json();
            } else {
                return -1;
            }
        } catch (error) {
            console.error(error);
        }
    }

    async deleteTable(tableId) {
        try {
          const response = await this.httpClient.destroy('Tables/'+tableId);
    
            if (response.status === 204) {
                return true;
            } else {
                return false;
            }
        } catch (error) {
            console.error(error);
        }
    }
}