import React from "react";
import { Button } from "@chakra-ui/react";
import { useMsal } from "@azure/msal-react";
import { loginRequest } from "../../config/authConfig";

export function SignInButton({ml, mr}) {
  const { instance } = useMsal();

  const handleLogin = () => {
    instance.loginRedirect(loginRequest).catch((e) => {
        console.log(e);
    });
  };
  return (
    <Button fontSize={18} ml={ml} mr={mr} colorScheme='teal' p="4" alignSelf="center" onClick={() => handleLogin()}>Bejelentkezés</Button>
  );
};
