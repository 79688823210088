import React, { useState, useEffect } from 'react';
import {
	Center,
	Heading,
	VStack,
	Flex,
	Button,
	Link
} from '@chakra-ui/react';
import ListItem from '../components/orders/orderListItem.jsx'
import FetchClient from '../serviceclients/fetchclient';
import OrderService from '../services/orderservice.js';
import NetworkError from '../components/connection/network_error';
import Spinner from '../components/connection/spinner';
import AccessForbidden from './accessforbidden.js';
import { MdPayment } from "react-icons/md";
import ReservationsService from '../services/reservationsservice';
import { useLocation } from "react-router-dom";
import {
	Alert,
	AlertIcon,
  } from '@chakra-ui/react'
import { useAuth } from '../providers/authProvider.jsx';

function timeout(delay) {
	return new Promise( res => setTimeout(res, delay) );
}

function useQuery() {
	const { search } = useLocation();
  
	return React.useMemo(() => new URLSearchParams(search), [search]);
  }

export default function Basket({actId, reservations}) {
	const [orders, setOrders] = useState([]);
	const [isLoaded, setIsLoaded] = useState(false);

	const authContext = useAuth();

	let query = useQuery();
	const reservationId = query.get("id");

	// eslint-disable-next-line
	const [isPaid, setIsPaid] = useState(!(actId==reservationId));

	const doFetch = async () => {
		setIsLoaded(false);
		await timeout(1000);
		const orderService = new OrderService(new FetchClient(authContext));
		const fetchOrders = async ()=>{
			try{
				const ords = await orderService.getOrders(reservationId);
				setOrders(ords);
				setIsLoaded(true);
			}catch(error){
				console.log(error);
			}
		}
		fetchOrders();
	}

	const toBasket = "/basket?id=" + reservationId;

	const finishFunction = async (url) => {
        const reservationsService = new ReservationsService(new FetchClient(authContext));
        try {
            await reservationsService.finishReservation(reservationId);
			setIsPaid(true);
			window.location.reload(); 
        } catch (error) {
            console.log(error);
        }
    };

	// eslint-disable-next-line
	useEffect(() => { doFetch(); }, [isPaid]);

	var total = 0;
	if(orders.length>0){
		orders.forEach(element => {
			total += element.product.ar*element.quantity;
		});
	}

	var payment;
	// eslint-disable-next-line
	if(!isPaid){
		payment = <Button alignSelf="flex-end" textAlign="center" fontSize="2xl" p="5" leftIcon={<MdPayment />} colorScheme='teal' onClick={() => finishFunction(toBasket)} >Fizetek</Button>;
	} else {
		payment = <Alert status='success' variant='subtle'><AlertIcon />{Math.ceil(0.001*total)} hűségpont elkönyvelve</Alert>;
	}

	if(reservationId===0||reservationId===undefined||!(reservations.includes(Number(reservationId)))){
		return <AccessForbidden/>
	}else if (orders === undefined) {
		return <NetworkError/>
	} else if (!isLoaded){
		return <Spinner/>
	} else {
		return (
			<>
			<Center ml="5" mr="5">
				<VStack mt="10" mb="10" >
					<Center>
						<Heading mb="10" >Rendelésem</Heading>
					</Center>
					{orders.length>0 && (orders.map(o => 
						<ListItem key={"order"+o.id} order={o} onChange={doFetch} actual={!isPaid} />
					))}
					{total!==0 && (<><Flex dir='row' justifyContent="flex-end" w="100%" my="5">
						<Heading mr="5">Végösszeg:</Heading>
						<Heading>{total} Ft</Heading>
					</Flex>
					{payment}</>)}
				</VStack>
			</Center>
			</>);
	}


};
