import React from 'react';
import {
    Drawer,
    DrawerBody,
    DrawerFooter,
    DrawerOverlay,
    DrawerContent,
    DrawerCloseButton,
    IconButton,
    useDisclosure,
    Stack,
    Link,
    Button
} from '@chakra-ui/react'
import { HamburgerIcon } from '@chakra-ui/icons'
import { useNavigate } from "react-router-dom";
import { useIsAuthenticated } from "@azure/msal-react";
import { SignInButton } from '../profile/signInButton.jsx';

export default function MobileDrawer({nameParam, role}) {
    const { isOpen, onOpen, onClose } = useDisclosure()
    const btnRef = React.useRef()

    const navigate = useNavigate();
    const handleClick = (url) => {
        navigate(url);
        onClose();
    }

    const isAuthenticated = useIsAuthenticated();

    let statButton = null;
    if(role===1){
        statButton = <Link onClick={() => handleClick("/statistics")} _hover={false} fontSize={24}>Statisztika</Link>;
    }
  
    return (
      <>
        <IconButton icon={<HamburgerIcon fontSize="3xl"/>} ref={btnRef} onClick={onOpen} backgroundColor="transparent" alignSelf="center" justifySelf="center" m="5" />
        <Drawer
          isOpen={isOpen}
          placement='right'
          onClose={onClose}
          finalFocusRef={btnRef}
        >
          <DrawerOverlay />
          <DrawerContent>
            <DrawerCloseButton />

            <DrawerBody>
                <Stack spacing={8} direction='column' mt="10" >
                    <Link onClick={() => handleClick("/")} fontSize={30} _hover={false} fontWeight={'bold'} >Éttermünk</Link>
                    <Link onClick={() => handleClick("/reservation")} fontSize={24} _hover={false} >Foglalás</Link>
                    <Link onClick={() => handleClick("/reservations")} _hover={false} fontSize={24}>Foglalások</Link>
                    <Link onClick={() => handleClick("/products")} _hover={false} fontSize={24}>Étlap</Link>
                    <Link onClick={() => handleClick("/ratings")} _hover={false} fontSize={24}>Vélemények</Link>
                    <Link onClick={() => handleClick("/gallery")} _hover={false} fontSize={24}>Galéria</Link>
                    {statButton}
                </Stack>
            </DrawerBody>
  
            <DrawerFooter>
              {isAuthenticated ? <Button fontSize={18} colorScheme='teal' p="4" alignSelf="center" onClick={() => handleClick("/profile")}>{nameParam}</Button> : <SignInButton ml="0" mr="0" />}
            </DrawerFooter>
          </DrawerContent>
        </Drawer>
      </>
    )
}
