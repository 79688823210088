const b2cPolicies = {
    names: {
        signUpSignIn: "B2C_1_Ettermunk-userflow",
        forgotPassword: "b2c_1_reset",
        editProfile: "b2c_1_edit"
    },
    authorities: {
        signUpSignIn: {
            authority: "https://ettermunkorg.b2clogin.com/ettermunkorg.onmicrosoft.com/B2C_1_Ettermunk-userflow",
        },
        forgotPassword: {
            authority: "https://ettermunkorg.b2clogin.com/ettermunkorg.onmicrosoft.com/b2c_1_reset",
        },
        editProfile: {
            authority: "https://ettermunkorg.b2clogin.com/ettermunkorg.onmicrosoft.com/b2c_1_edit"
        }
    },
    authorityDomain: "ettermunkorg.b2clogin.com"
}

export default b2cPolicies;
