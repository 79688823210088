import React, { useState } from 'react';
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
  ButtonGroup,
  Flex,
  Image,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import cowImage from '../../resources/cow.png';
import { MdDelete, MdModeEdit } from 'react-icons/md';
import ProductsEditForm from './ProductEditFood';
import FetchClient from '../../serviceclients/fetchclient';
import ProductService from '../../services/productservice';
import apiConfig from '../../config/apiConfig.js'
import OrderConfirmer from '../orders/orderConfirmer.jsx'
import { useAuth } from '../../providers/authProvider.jsx';

export default function ListItem({product, onChange, role, reservationId}){

  //edit étel formhoz kellenek, hogy mikor lássuk
  const [isModalOpen, setIsModalOpen] = useState(false);
  const openModal = () => {
    setIsModalOpen(true);
  };
  const closeModal = () => {
    setIsModalOpen(false);
  };

  const authContext = useAuth();

  const deleteFunction = (productId) => {
    const productService = new ProductService(new FetchClient(authContext));
    const destroyProduct = async () => {
      try{
        await productService.destroyProduct(productId);
      }catch(error){
        console.log(error);
      }
    }
    destroyProduct();
    onChange();
  };
  const DeleteConfirmation = () => {
    const { isOpen, onOpen, onClose } = useDisclosure()
    const cancelRef = React.useRef()

    return (
      <>
        <Button colorScheme='red' variant='outline' fontSize="sm" onClick={onOpen}>{<MdDelete/>}</Button>

        <AlertDialog
          isOpen={isOpen}
          leastDestructiveRef={cancelRef}
          onClose={onClose}
        >
          <AlertDialogOverlay>
            <AlertDialogContent width="90%">
              <AlertDialogHeader fontSize='lg' fontWeight='bold'>Termék törlése</AlertDialogHeader>

              <AlertDialogBody>
                Biztosan törölni szeretnéd? Ezt a műveletet nem tudod visszavonni.
              </AlertDialogBody>

              <AlertDialogFooter>
                <Button ref={cancelRef} onClick={onClose}>
                  Mégse
                </Button>
                <Button colorScheme='red' onClick={() => deleteFunction(product.id)} ml={3}>
                  Törlés
                </Button>
              </AlertDialogFooter>
            </AlertDialogContent>
          </AlertDialogOverlay>
        </AlertDialog>
      </>
    )
  }

  let editAndDeleteButtons = undefined;
  let orderButton = undefined;
  if(role===1){
    editAndDeleteButtons = (<><Button onClick={openModal} >{<MdModeEdit/>}</Button>
                            <ProductsEditForm closeModal={closeModal} isModalOpen={isModalOpen} prod={product} onChange={onChange}/>
                            <DeleteConfirmation/></>);
  }
  if(role===0){
    orderButton = <OrderConfirmer productId={product.id} reservationId={reservationId}/>;
  }

  const kep = product.imageFilePath==="" ? cowImage : apiConfig.imageroute+product.imageFilePath;

  /* Egy listaelemet rak össze, kap 1 product-ot és annak az adatait
      berakja egy flex-be*/
  return (
    <Flex flexWrap="wrap" fontSize="sm" bg='lightgray' w='100%' p={4} borderRadius={10} alignItems="center" justifyContent="space-around" mb='2'>
      <Image m="1" src={kep} alt="image" maxHeight="80px" />
      <Text m="1">{product.megnevezes}</Text>
      <Text m="1">{product.ar} Ft</Text>
      <ButtonGroup spacing="4" my="4">
        {orderButton}
        {editAndDeleteButtons}
      </ButtonGroup>
    </Flex>
  )
}