import React from 'react';
import {
	Center,
	Text
} from '@chakra-ui/react'
import { SignInButton } from '../components/profile/signInButton';

export default function LoginToSee() {
    return (
        <Center mt="10">
                <SignInButton ml="10"/>
                <Text ml="4">
                    szükséges ezen oldal megtekintéséhez
                </Text>
        </Center>
    );
};
