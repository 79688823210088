import React, { useEffect, useState } from 'react';
import { Center,
        Heading, 
        VStack} from "@chakra-ui/react";
import { useMsal } from "@azure/msal-react";
import { SignOutButton } from "./signOutButton";
import { EditProfileButton } from "./editProfileButton.jsx";
import UserService from '../../services/userservice.js';
import FetchClient from '../../serviceclients/fetchclient.js';
import Spinner from '../connection/spinner.js'
import NetworkError from '../connection/network_error.js'
import { useAuth } from '../../providers/authProvider.jsx';

export const ProfileData = () => {
    const { accounts } = useMsal();
    const [userData, setUserData] = useState([]);
	const [isLoaded, setIsLoaded] = useState(false);
    const authContext = useAuth();

    const doFetch = async () => {
		setIsLoaded(false);
		const userService = new UserService(new FetchClient(authContext));
		const fetchUserData = async ()=>{
			try{
				const usrdta = await userService.getUserData(accounts[0]);
				setUserData(usrdta);
				setIsLoaded(true);
			}catch(error){
				console.log(error);
			}
		}
		fetchUserData();
	}

    // eslint-disable-next-line
	useEffect(() => { doFetch(); }, []);

    var profileData;
    if(userData===undefined){
		profileData = <NetworkError />;
	}else if(!isLoaded){
		profileData = <Spinner />;
	}else if(userData.role===0){
        profileData = <Heading mt="10" fontSize="20">Hűségpontok: {userData.points} pont</Heading>;
    }else{
        profileData = <VStack><Heading mt="10" fontSize="20">Admin jogok aktívak</Heading><Heading mt="10" fontSize="20">Hűségpontok: {userData.points}</Heading></VStack>;
    }

    if(accounts[0]===undefined){
        return <></>
    }else{
        return (
            <>
            <Center mt="20" textAlign="center" w="100%">
                <VStack backgroundColor="lightgray" borderRadius="md" ml="5" mr="5" p="10">
                <Heading>Üdvözlünk,<br/>{accounts[0].name}!</Heading>
                {profileData}
                <EditProfileButton accountId={accounts[0].username}/>
                <SignOutButton/>
                </VStack>
            </Center>
            </>
        );
    }
};
