import React from 'react';
import {
	VStack,
	StackDivider,
	Center,
	Text,
	Image
} from '@chakra-ui/react'
import { pageNotFoundURL } from '../resources/strings/nopage/urls';
import page_not_found from '../resources/images/nopage/page_not_found.png'

export default function NoPage() {
    return (
        <Center>
            <VStack my="10" mx="5" fontSize='xl' width="4xl" divider={<StackDivider borderColor='gold' />} spacing={4}>
                <Text>
                    Page not found
                </Text>
                <Image boxShadow="lg" borderRadius="md" src={page_not_found} fallbackSrc={pageNotFoundURL} />
            </VStack>
        </Center>
    );
};