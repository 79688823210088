export const introductionParagraph1 = 'Üdvözöljük Önöket, és köszöntjük az oldalunkon! Örömünkre szolgál, hogy itt lehetnek, hogy egy különleges gasztronómiai élménybe kalauzoljuk Önöket. Az éttermünk minőséget, ízek harmóniáját és vendégközpontú szolgáltatásokat kínál, amelyek igazán megfogják Önöket.';

export const introductionParagraph2 = 'Az étterem egy lenyűgöző, romantikus környezetben található. Csendes kis utcában helyezkedik el, távol a város zajától, így ideális választás párkapcsolatok ünnepléséhez, családi vacsorákhoz és üzleti találkozókhoz egyaránt. A belső tér letisztult, de meleg atmoszférával várja vendégeinket.';

export const introductionParagraph3 = 'Étlapunkon megtalálhatók a hagyományos, magyaros ízek, de azok számára is kínálunk választékot, akik különlegesebb fogásokat keresnek. Szakácsaink alaposan válogatnak az alapanyagokban, hogy minden egyes ételünk kivételes ízélményt nyújtson. Az étlapunkon gyakoriak a szezonális fogások, amelyek a friss alapanyagok szerelmeseinek örömére szolgálnak.';

export const introductionParagraph4 = 'Az étterem büszkélkedhet egy kiváló borválasztékkal is. Sommelierünk gondosan válogatja ki a borokat, hogy azok tökéletesen harmonizáljanak az ételeinkkel. Borkóstolókra is lehetőség van, hogy az ételeink ízeit különleges borokkal egészítsük ki.';

export const introductionParagraph5 = 'Az étterem nem csupán egy étkezőhely, hanem egy helyszín is, ahol különleges eseményeket rendezhet. Legyen szó esküvőről, születésnapról vagy üzleti rendezvényről, csapatunk az Ön elképzeléseit valóra váltja, és gondoskodik róla, hogy az esemény emlékezetes legyen.';

export const introductionParagraph6 = 'Vendégközpontú szemléletünk az Önök kényelmét helyezi előtérbe. Készséges és profi személyzetünk mindent megtesz azért, hogy vendégeinknek a lehető legkellemesebb élményt nyújtsák. Éttermünk alkalmas romantikus vacsorákhoz, családi ebédekhez, üzleti találkozókhoz, és még sok más alkalomhoz is.';

export const introductionParagraph7 = 'Amennyiben kérdései merülnek fel, vagy foglalni szeretne asztalt, ne habozzon kapcsolatba lépni velünk. A Kapcsolat oldalon elérhető elérhetőségeinken keresztül bármikor felveheti velünk a kapcsolatot, és szívesen segítünk Önnek.';

export const introductionParagraph8 = 'Az étterem csapatának nevében köszönjük, hogy ellátogattak weboldalunkra. Reméljük, hogy hamarosan vendégeink között üdvözölhetjük Önöket, és együtt élvezhetjük a kulináris élmények világát.';

export const introductionParagraph9 = 'Köszönjük, hogy velünk tartanak!';