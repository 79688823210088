import React from 'react';
import { Flex, Text, InputGroup, InputLeftAddon, Input, Button } from '@chakra-ui/react';
import { MdDelete } from 'react-icons/md';

export default function TableListItem({ table, handleTableChange, handleDeleteButtonClicked }) {
  return (
    <React.Fragment key={table.id}>
      <Flex fontSize="md" bg='lightgray' mx="5" p="1%" borderRadius={10} alignItems="center" w='80%'>
        <Text w='10%' textAlign='center'>
          ID: {table.id}
        </Text>

        <Text w='20%' textAlign='center'>
          Kapacitás: {table.capacity}
        </Text>

        <InputGroup size='sm' w='20%' marginEnd='10%'>
          <InputLeftAddon children='Kezdete:' />
          <Input type='number' defaultValue={table.rowStart} onChange={(e) => handleTableChange(e, 'RowStart', table.id)} />
          <Input type='number' defaultValue={table.columnStart} onChange={(e) => handleTableChange(e, 'ColumnStart', table.id)} />
        </InputGroup>

        <InputGroup size='sm' w='20%' marginEnd='10%'>
          <InputLeftAddon children='Vége:' />
          <Input type='number' defaultValue={table.rowEnd} onChange={(e) => handleTableChange(e, 'RowEnd', table.id)} />
          <Input type='number' defaultValue={table.columnEnd} onChange={(e) => handleTableChange(e, 'ColumnEnd', table.id)} />
        </InputGroup>
        <Button
          w='10%'
          onClick={() => { handleDeleteButtonClicked(table.id) }}
          colorScheme='red'
          variant='outline'
        >
          <MdDelete />
        </Button>
      </Flex>
    </React.Fragment>
  );
}
