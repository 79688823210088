import React, { useEffect, useState } from 'react';
import {
	Heading,
	VStack,
	Center,
	Box
} from "@chakra-ui/react";
import ReservationListItem from '../components/reservations/ReservationListItem.js';
import ReservationsService from '../services/reservationsservice.js';
import FetchClient from '../serviceclients/fetchclient.js';
import Spinner from '../components/connection/spinner.js';
import NetworkError from '../components/connection/network_error.js';
import LoginToSee from './loginToSee.js';
import { useAuth } from '../providers/authProvider.jsx';

function timeout(delay) {
	return new Promise(res => setTimeout(res, delay));
}

export default function Reservations({ role, accountId }) {
	const [isLoaded, setIsLoaded] = useState(false);
	const [userReservations, setUserReservations] = useState(undefined);
	const [adminReservations, setAdminReservations] = useState(undefined);

	const authContext = useAuth();

	const doFetch = async () => {
		setIsLoaded(false);
		await timeout(1000);
		const reservationsService = new ReservationsService(new FetchClient(authContext));
		const fetchReservations = async () => {
			try {
				if (role === 1) {
					const res = await reservationsService.getReservationsForAdmin(accountId);
					if(res.status===200 || res.length>0) setAdminReservations(res);
				}
				if (role === 0){
					const res = await reservationsService.getReservationsForUser(accountId);
					if(res.status===200  || res.length>0) setUserReservations(res);
				}
				setIsLoaded(true);
			} catch (error) {
				console.log(error);
			}
		}
		fetchReservations();
	}

	// eslint-disable-next-line
	useEffect(() => { doFetch(); }, []);

	if (role === -1) {
		return <LoginToSee />
	} else if (!isLoaded) {
		return <Spinner />;
	} else if (role===undefined) {
		return <NetworkError />;
	} else {
		return (
			<>
				<Center>
					<VStack maxWidth='100%' mt="10" mb="10" spacing='10px' width="700px">
						{role === 0 && (
							<>
								<Box fontSize="xl" pb='20px'>
									<Heading textAlign='left'>Foglalások</Heading>
								</Box>
								{
									userReservations!==undefined && (userReservations.map(
										(reservation) =>
											<ReservationListItem key={reservation.id} user={reservation.user} isUserReservation={true}
												accountId={accountId} role={role} {...reservation} />
									))
								}
							</>
						)}
						{role === 1 && (
							<>
								<Box fontSize="xl" pb="20px" textAlign="left"> 
									<Heading>Vendégek Foglalásai</Heading>
								</Box>
								{
									adminReservations!==undefined && (adminReservations.map(
										(reservation) =>
											<ReservationListItem key={reservation.id} isUserReservation={false}
												accountId={accountId} role={role} {...reservation} />
									))
								}
							</>)}
					</VStack>
				</Center>
			</>
		);
	}
};