import React, { useState } from 'react';
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    Button,
    Input,
    FormControl,
    FormLabel,
    useDisclosure,
    Text
  } from '@chakra-ui/react'
import { MdModeEdit, MdAdd } from "react-icons/md"
import CategoryService from '../../services/categoryservice';
import FetchClient from '../../serviceclients/fetchclient';
import { useAuth } from '../../providers/authProvider';

export default function CategoryForm({isEdit, category, onChange}) {
    const { isOpen, onOpen, onClose } = useDisclosure()
    const [catName, setCatName ] = useState('');
    const [emptyError, setEmptyError] = React.useState(false)
    const [lengthError, setLengthError] = React.useState(false)
  
    const initialRef = React.useRef(null)
    const finalRef = React.useRef(null)
    const authContext = useAuth();

    let button, header, nameTextBox;
    if (isEdit) {
        button = <Button onClick={onOpen} leftIcon={<MdModeEdit />} colorScheme='yellow' variant='outline' fontSize="sm">Szerkesztés</Button>;
        header = "Kategória szerkesztése";
        nameTextBox = <Input type="text" id="catName" name="catName" ref={initialRef} placeholder='kategória neve' defaultValue={category.name} onChange={(event) => setCatName(event.target.value)} />;
    } else {
        button = <Button onClick={onOpen} leftIcon={<MdAdd />} colorScheme='blue' variant='outline' fontSize="sm">Új kategória</Button>;
        header = "Kategória hozzáadása";
        nameTextBox = <Input type="text" id="catName" name="catName" ref={initialRef} placeholder='kategória neve' onChange={(event) => setCatName(event.target.value)} />;
    }

    const submitFunction = (event) => {
      event.preventDefault();
      const ok = valid();
      if(ok){
        if(isEdit){
          updateFunction(event);
        }else{
          saveFunction(event);
        }
      }
    }

    const valid = () => {
      if(catName.length > 15){
        setLengthError(true);
        setEmptyError(false);
        return false;
      } else if(catName.length === 0){
        setEmptyError(true);
        setLengthError(false);
        return false;
      } else{
        setLengthError(false);
        setEmptyError(false);
        return true;
      }
    }

    const saveFunction = (event) => {
      const categoryService = new CategoryService(new FetchClient(authContext));
      const postCategory = async () => {
        try{
          await categoryService.createCategory({id: 0, name: catName});
        }catch(error){
          console.log(error);
        }
      }
      postCategory();
      onClose();
      onChange();
    };

    const updateFunction = (event) => {
      const categoryService = new CategoryService(new FetchClient(authContext));
      const updateCategory = async () => {
        try{
          await categoryService.updateCategory({id: category.id, name: catName});
        }catch(error){
          console.log(error);
        }
      }
      updateCategory();
      onClose();
      onChange();
    };
  
    return (
      <>
        {button}
  
        <Modal
          initialFocusRef={initialRef}
          finalFocusRef={finalRef}
          isOpen={isOpen}
          onClose={onClose}
        >
          <ModalOverlay />
          <ModalContent width="90%">
            <form onSubmit={submitFunction}>
              <ModalHeader>{header}</ModalHeader>
              <ModalCloseButton />
              <ModalBody pb={6}>
                <FormControl>
                  <FormLabel>Kategória neve</FormLabel>
                  {nameTextBox}
                  { emptyError ? <Text textColor="red.400" fontSize="sm" p="1" pl="2">Nem lehet üres a név.</Text> : null }
                  { lengthError ? <Text textColor="red.400" fontSize="sm" p="1" pl="2">Nem lehet 15 karakternél hosszabb a név.</Text> : null }
                </FormControl>
              </ModalBody>
    
              <ModalFooter>
                <Button type="submit" colorScheme='blue' mr={3}>
                  Mentés
                </Button>
                <Button onClick={onClose}>Mégse</Button>
              </ModalFooter>
            </form>
          </ModalContent>
        </Modal>
      </>
    )
  }
