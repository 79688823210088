import React, { useEffect, useState } from 'react';
import {
	VStack,
	Heading,
	Center,
	Flex,
} from '@chakra-ui/react'
import ListItem from '../components/categories/CategoryListItem.js'
import CategoryForm from '../components/categories/CategoryForm.js'
import CategoryService from '../services/categoryservice.js';
import FetchClient from '../serviceclients/fetchclient.js';
import Spinner from '../components/connection/spinner.js'
import NetworkError from '../components/connection/network_error.js';
import AccessForbidden from './accessforbidden.js'
import { useAuth } from '../providers/authProvider.jsx';

function timeout(delay) {
	return new Promise( res => setTimeout(res, delay) );
}

export default function Categories({role}) {
	const [categories, setCategories] = useState([]);
	const [isLoaded, setIsLoaded] = useState(false);
	const authContext = useAuth();

	const doFetch = async () => {
		setIsLoaded(false);
		await timeout(1000);
		const categoryService = new CategoryService(new FetchClient(authContext));
		const fetchCategories = async ()=>{
			try{
				const cats = await categoryService.getCategories();
				setCategories(cats);
				setIsLoaded(true);
			}catch(error){
				console.log(error);
			}
		}
		fetchCategories();
	}

	// eslint-disable-next-line
	useEffect(() => { doFetch(); }, []);

	if(role!==1){
		return <AccessForbidden/>
	}else if(categories===undefined){
		return <NetworkError />
	}else if(!isLoaded){
		return <Spinner />
	}else{
		return (
			<>
			<Center ml="5" mr="5">
				<VStack mt="10" mb="10" >
					<Flex justifyContent="space-between" w='100%'>
						<Heading mb="10" >Kategóriák</Heading>
						<CategoryForm isEdit={false} onChange={() => doFetch()} />
					</Flex>
					{categories.map(cat => 
						<ListItem key={"cat"+cat.id} category={cat} onChange={doFetch} />
					)}
				</VStack>
			</Center>
			</>)
	}
};
