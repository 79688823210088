import React, { useState } from 'react';
import {
    Popover,
    PopoverTrigger,
    PopoverContent,
    PopoverArrow,
    PopoverCloseButton,
    Input,
    Stack,
    ButtonGroup,
    Button,
    HStack,
    FocusLock,
    useDisclosure,
    Text,
    Alert,
    AlertIcon,
    AlertTitle,
    AlertDescription,
    Link
  } from '@chakra-ui/react'
import OrderService from '../../services/orderservice.js'
import FetchClient from '../../serviceclients/fetchclient';
import { useAuth } from '../../providers/authProvider.jsx';

// import  FocusLock from "react-focus-lock"
  
  // 2. Create the form
  const Form = ({ firstFieldRef, onCancel, productId, reservationId }) => {
    const [qty, setQty ] = useState(1);
    const [success, setSuccess ] = useState(false);

    const authContext = useAuth();

    const submitFunction = (event) => {
      event.preventDefault();
      const ok = valid();
      if(ok){
        saveFunction(event);
      }
    }

    const valid = () => {
      if(typeof qty === 'number'){
        return true
      } else {
        return false;
      }
    }

    const saveFunction = (event) => {
      const orderService = new OrderService(new FetchClient(authContext));
      const postOrder = async () => {
        try{
          await orderService.createOrder({id: 0, quantity: qty, reservationId: reservationId, productId: productId});
          setSuccess(true);
        }catch(error){
          console.log(error);
        }
      }
      postOrder();
    };

    const toBasket = "/basket?id=" + reservationId;

    if(success){
      return(
        <Alert
          status='success'
          flexDirection='column'
          alignItems='center'
          justifyContent='center'
          textAlign='center'
          backgroundColor="transparent"
        >
          <AlertIcon boxSize='40px' mr={0} />
          <AlertTitle mt={4} mb={1} fontSize='lg'>
            Sikeres rendelés!
          </AlertTitle>
          <AlertDescription>
            <Link href={toBasket}>A kosaram</Link>
          </AlertDescription>
        </Alert>
      );
    } else if(reservationId!==0 && reservationId!==undefined){
        return (
          <form onSubmit={submitFunction}>
            <Stack spacing={4}>
              
              <Text fontWeight="semibold">Mennyiség:</Text>
              <QuantityInput onQtyChange={(q) => setQty(q)}/>
              <ButtonGroup display='flex' justifyContent='flex-start'>
                <Button variant='outline' onClick={onCancel}>
                  Mégse
                </Button>
                <Button type="submit" colorScheme='teal'>
                  Megrendelem
                </Button>
              </ButtonGroup>
            </Stack>
          </form>
          )
    }else{
        return <Text color="red.500">Ahhoz, hogy rendelni tudj, nyomj a foglalásoknál a foglalás megkezdése gombra a mostani rendelésednél!</Text>
    }
  }
  
  // 3. Create the Popover
  // Ensure you set `closeOnBlur` prop to false so it doesn't close on outside click
 export default function PopoverForm({productId, reservationId}) {
    const { onOpen, onClose, isOpen } = useDisclosure()
    const firstFieldRef = React.useRef(null)
  
    return (
      <>
        <Popover
          isOpen={isOpen}
          initialFocusRef={firstFieldRef}
          onOpen={onOpen}
          onClose={onClose}
          placement='bottom'
          closeOnBlur={false}
        >
          <PopoverTrigger>
            <Button fontSize="sm">Megrendelem</Button>
          </PopoverTrigger>
          <PopoverContent p={5}>
            <FocusLock returnFocus persistentFocus={false}>
              <PopoverArrow />
              <PopoverCloseButton />
              <Form firstFieldRef={firstFieldRef} onCancel={onClose} productId={productId} reservationId={reservationId}  />
            </FocusLock>
          </PopoverContent>
        </Popover>
      </>
    )
  }
  
function QuantityInput({ onQtyChange }) {
    const [value, setValue] = useState(1);
  
    const handleIncrement = () => {
      if (value < 9) {
        const newValue = value + 1;
        setValue(newValue);
        onQtyChange(newValue);
      }
    };
  
    const handleDecrement = () => {
      if (value > 1) {
        const newValue = value - 1;
        setValue(newValue);
        onQtyChange(newValue);
      }
    };
  
    const handleInputChange = (event) => {
      const newValue = parseInt(event.target.value, 10) || 1;
      setValue(newValue);
      onQtyChange(newValue);
    };
  
    return (
      <HStack maxW='140px'>
        <Button onClick={handleDecrement}>-</Button>
        <Input
          value={value}
          onChange={handleInputChange}
          type="number"
          min={1}
          max={9}
        />
        <Button onClick={handleIncrement}>+</Button>
      </HStack>
    );
}  
  