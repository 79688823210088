import React, { useEffect, useState } from 'react';
import {
	Center,
	Textarea,
	Heading,
	VStack,
	useBreakpointValue,
	SliderMark,
	Slider,
	SliderThumb,
	SliderTrack,
	Tooltip,
	SliderFilledTrack,
	Box,
	Button,
	Flex, Spinner,
} from '@chakra-ui/react';
import SaveRatingButton from '../components/rating/ratingSubmitButton';
import CommentBox from '../components/rating/ratingBox';
import FetchClient from '../serviceclients/fetchclient';
import RatingService from '../services/ratingservice';
import NetworkError from '../components/connection/network_error';
import { useAuth } from '../providers/authProvider';

function timeout(delay) {
	return new Promise( res => setTimeout(res, delay) );
}

export default function Ratings({role, accountId}) {
	const authContext = useAuth();

	const textareaWidth = useBreakpointValue({ base: '100%', md: '100%' });
	const maxWidth = useBreakpointValue({ base: '100%', md: '700px' });
	const sliderWidth = useBreakpointValue({ base: '90%', md: '80%' });
	const textareaHeight = useBreakpointValue({ base: '160px', md: '160px' });

	const [sliderValue, setSliderValue] = React.useState(3);
	const [showTooltip, setShowTooltip] = React.useState(false);

	const [isLoaded, setIsLoaded] = useState(false);
	const [text, setText] = useState('')

	const [ratings, setRatings] = useState([]);
	const handleButtonClicked = () => {
			openDialog();
	};
	//Ez felel azért, hogy látjuk vagy nem látjuk a dialogust
	const [isDialogOpen, setIsDialogOpen] = useState(false);
	const openDialog = () => {
		setIsDialogOpen(true);
	};
	const closeDialog = () => {
		setIsDialogOpen(false);
	};

	const doFetch = async () => {
		setIsLoaded(false);
		await timeout(1000);
		const ratingService = new RatingService(new FetchClient(authContext));
		const fetchRatings = async ()=>{
			try{
				const ratingss = await ratingService.getRatings();
				console.log(ratingss);
				setRatings(ratingss);
				setIsLoaded(true);
			}catch(error){
				console.log(error);
			}
		}
		fetchRatings();
	}

	// eslint-disable-next-line
	useEffect(() => { doFetch(); }, []);

	function extractUsernameFromEmail(email) {
		// Check if the email contains '@'
		const atIndex = email.indexOf('@');

		if (atIndex !== -1) {
			// Extract the part before '@'
			return email.substring(0, atIndex);
		} else {
			// If there is no '@', return the whole email
			return email;
		}
	}

	function isButtonAbled() {
		var ret = true;
		// eslint-disable-next-line
		ratings.map((r) => {
			if (r.user.id === accountId){
				ret = false;
			}
		});
		return ret;
	}

	let ratingWritingBox = undefined;
	if (role === 0 && isButtonAbled()) {
		ratingWritingBox = (
			<VStack w="100%">
				<Textarea
					placeholder="Írjon véleményt.."
					style={{
						width: textareaWidth,
						maxWidth: maxWidth,
						height: textareaHeight,
						border: '1px solid #ccc',
						borderRadius: '4px',
						resize: 'none',
						overflowY: 'auto',
					}}
					value={text}
					onChange={(e) => setText(e.target.value)}
					isDisabled={!isButtonAbled()}
				/>

				<Flex
					direction={{ base: 'column', md: 'row' }}
					align="center"
					justify="space-between"
					w={maxWidth}
				>
					<Box w={sliderWidth}>
						<Slider
							id="slider"
							defaultValue={3}
							min={1}
							max={5}
							step={0.1}
							colorScheme="teal"
							onChange={(v) => setSliderValue(v)}
							onMouseEnter={() => setShowTooltip(true)}
							onMouseLeave={() => setShowTooltip(false)}
							isDisabled={!isButtonAbled()}
						>
							<SliderMark value={1} mt="1" ml="-2.5" fontSize="sm">
								1⭐
							</SliderMark>
							<SliderMark value={2} mt="1" ml="-2.5" fontSize="sm">
								2⭐
							</SliderMark>
							<SliderMark value={3} mt="1" ml="-2.5" fontSize="sm">
								3⭐
							</SliderMark>
							<SliderMark value={4} mt="1" ml="-2.5" fontSize="sm">
								4⭐
							</SliderMark>
							<SliderMark value={5} mt="1" ml="-2.5" fontSize="sm">
								5⭐
							</SliderMark>
							<SliderTrack>
								<SliderFilledTrack />
							</SliderTrack>
							<Tooltip
								hasArrow
								bg="teal.500"
								color="white"
								placement="top"
								isOpen={showTooltip}
								label={`${sliderValue}`}
							>
								<SliderThumb />
							</Tooltip>
						</Slider>
					</Box>
					<Button
						colorScheme="teal"
						size="sm"
						mt={{ base: 4, md: 0 }} // Add margin-top in mobile mode
						onClick={() => handleButtonClicked()}
						isDisabled={!isButtonAbled()}
					>
						Feltöltés
					</Button>
					<SaveRatingButton closeDialog={closeDialog}
														isDialogOpen={isDialogOpen}
														text={text}
														rating={sliderValue}
														user={accountId}/>
				</Flex>
			</VStack>
		);
	} else if (role === 0 && !isButtonAbled()) {
		ratingWritingBox = (
		<VStack w="100%">
			<h2>Köszönjük, hogy időt szánt véleménye megírására!</h2>
		</VStack>
		);
	}

	if(ratings === undefined) {
		return <NetworkError/>
	} else if (!isLoaded) {
		return  (
		<Center ml="5" mr="5" mt="10">
			<Spinner/>
		</Center>)

	} else {
		return (
			<Center ml="5" mr="5">
				<VStack w="100%">
					<Heading textAlign="left" mt="3" mb="3">Vélemények</Heading>
					{ratingWritingBox}
					<br/>
					<VStack w="100%" spacing={4}>
						{ratings.map((item) => (
							<CommentBox
								key={item.id}
								maxWidth={maxWidth}
								name={item.user ? extractUsernameFromEmail(item.user.email) : 'Unknown'}
								rating={item.csillag}
								text={item.szoveg}
								deleteEnabled={item.user.id===accountId||role===1}
								onDelete={() => doFetch()}
								id={item.id}
							/>
						))}
					</VStack>
				</VStack>

			</Center>
		);
	}
};

