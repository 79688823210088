import React, { useState, useEffect } from 'react';
import {
	Table,
	Thead,
	Tbody,
	Tr,
	Th,
	Td,
	TableContainer,
	Center,
	Text,
	Flex,
	Spacer,
	VStack
} from '@chakra-ui/react'
import OrderService from '../services/orderservice';
import FetchClient from '../serviceclients/fetchclient.js';
import Spinner from '../components/connection/spinner.js';
import NetworkError from '../components/connection/network_error.js';
import { useAuth } from '../providers/authProvider.jsx';

function timeout(delay) {
	return new Promise(res => setTimeout(res, delay));
}

export default function Statistics() {
	const [isLoaded, setIsLoaded] = useState(false);
	const [statistics, setStatistics] = useState(undefined);

	const authContext = useAuth();

	const doFetch = async () => {
		setIsLoaded(false);
		await timeout(1000);
		const orderService = new OrderService(new FetchClient(authContext));
		const fetchReservations = async () => {
			try {
				setStatistics(await orderService.getStatisticsTotalIncome())
				setIsLoaded(true);
			} catch (error) {
				console.log(error);
			}
		}
		fetchReservations();
	}

	// eslint-disable-next-line
	useEffect(() => { doFetch(); }, []);

	if (!isLoaded) {
		return <Spinner />;
	}else if (statistics === undefined) {
		return <NetworkError />;
	}else {
		return (
			<Center>
				<VStack width='95%' spacing={7} py="7">
					<Flex bg='#B2F5EA' borderRadius={10} width='95%' alignItems="center" py="10" px="10" fontSize='large' border="2px solid #1EA1A1">
						<Text>Összbevétel:</Text>
						<Spacer />
						<Text> {statistics.totalIncome.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ")} Forint</Text>
					</Flex >
					<Text width='95%' fontSize='large' textAlign='left' px='10'>Készlet:</Text>
					<TableContainer width='95%' borderRadius={10} border="2px solid #1EA1A1">
						<Table variant='striped' colorScheme='teal'>
							<Thead bg='#1EA1A1'>
								<Tr>
									<Th>Termék</Th>
									<Th isNumeric>Eladott</Th>
									<Th isNumeric>Bevétel</Th>
									<Th isNumeric>Összbevétel(%)</Th>
								</Tr>
							</Thead>
							<Tbody>
								<Tr></Tr>
								{Object.keys(statistics.productStatistic).map((productName, index) => {
									const productData = statistics.productStatistic[productName];
									return (
										<Tr key={index}>
											<Td width='1%'>{productData.name}</Td>
											<Td width='1%' isNumeric>{productData.totalSold} db</Td>
											<Td width='1%' isNumeric>{productData.totalIncomeFromProduct.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ")} Ft</Td>
											<Td width='1%' isNumeric>{productData.percentageOfTotalIncome.toFixed(2)}%</Td>
										</Tr>
									);
								})}
							</Tbody>
						</Table>
					</TableContainer>
				</VStack>
			</Center>
		);
	}
}
