import React from 'react';
import {
    Flex,
    Text
} from '@chakra-ui/react'
import ButtonGroup from './EditDeleteButtons';

export default function ListItem({category, onChange}){
    return (
        <Flex bg='lightgray' w='100%' p={4} borderRadius={10} alignItems="center" justifyContent="space-between" mb="2">
            <Text fontSize="md" mr="5">{category.name}</Text>
            <ButtonGroup category={category} onChange={onChange}/>
        </Flex>
    );
};
