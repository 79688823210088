import React from 'react';
import {
	VStack,
	Center,
	Heading
} from '@chakra-ui/react';
import { BiMessageAltError } from 'react-icons/bi';


export default function NetworkError(){
    return <Center><VStack p="20"><BiMessageAltError fontSize="60"/><Heading textAlign="center">Sikertelen csatlakozás a szerverhez</Heading></VStack></Center>;
}
