// CommentBox.js
import React from 'react';
import { Box, Flex, HStack, IconButton, Text } from '@chakra-ui/react';
import { DeleteIcon } from '@chakra-ui/icons';
import RatingService from '../../services/ratingservice';
import FetchClient from '../../serviceclients/fetchclient';
import { useAuth } from '../../providers/authProvider';

const CommentBox = ({ maxWidth, name, rating, text, deleteEnabled, onDelete, id }) => {
  const authContext = useAuth();

  let stars = "";
  for(let i = 0; i < Math.round(rating); i++) stars = stars.concat("⭐");

  const deleteFunction = () => {
    const ratingService = new RatingService(new FetchClient(authContext));
    const deleteRating = async () => {
      try {
        await ratingService.destroyRating(id);
      } catch (error) {
        console.log(error);
        }
      }
    deleteRating();
    onDelete();
  }

  return (
    <Box
      border="1px"
      borderColor="teal.500"
      borderRadius="4px"
      p="4"
      mb="4"
      w={maxWidth} // Set the width to maxWidth
    >
      <Flex align="center"
            justify="space-between">
        <Text fontSize="lg" fontWeight="bold" mb="2">
          {name}
        </Text>
        <HStack>
          <Text fontSize="sm" color="teal.500" textAlign="right" mb="2">Rating:</Text>
          <Text fontSize="sm" color="teal.500" textAlign="right" mb="2">{rating}</Text>
          <Text fontSize="sm" color="teal.500" textAlign="right" mb="2">{stars}</Text>
        </HStack>
      </Flex>

      <Text>{text}</Text>

      {deleteEnabled && (<Flex justifyContent="flex-end">
        <IconButton variant="ghost" onClick={() => deleteFunction()}>
          <DeleteIcon color="red" />
        </IconButton>
      </Flex>)}
    </Box>
  );
};

export default CommentBox;
