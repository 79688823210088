import React from 'react';
import {
	VStack,
	StackDivider,
	Center,
	Text,
	Image
} from '@chakra-ui/react'
import { accessForbiddenURL } from '../resources/strings/nopage/urls';
import access_forbidden from '../resources/images/nopage/access_forbidden.png'

export default function AccessForbidden() {
    return (
        <Center>
            <VStack my="10" mx="5" fontSize='xl' width="4xl" divider={<StackDivider borderColor='gold' />} spacing={4}>
                <Text>
                    Ennek az oldalnak a megtekintéséhez nincs jogosultságod
                </Text>
                <Image boxShadow="lg" borderRadius="md" src={access_forbidden} fallbackSrc={accessForbiddenURL} />
            </VStack>
        </Center>
    );
};