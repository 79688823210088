import React, { useEffect, useState } from 'react';
import {
    Flex,
    Text,
    Spacer
} from '@chakra-ui/react'
import ActionButtons from './ActionButtons';

export default function ReservationListItem({ start, duration, peopleCount, state, id, userId, user, isUserReservation, accountId, role }) {
    const [reservationState, setReservationState] = useState(state);
    // Callback függvény a state változások kezelésére
    const handleStateChange = (newState) => {
        setReservationState(newState);
    };

    const [displayComponent, setDisplayComponent] = useState();

    function updateDisplayComponent() {
        setDisplayComponent(((reservationState === 3 && role !== 1) || reservationState === -1) ? null : (
            <Flex fontSize="md" bg='lightgray' mx="5" py="4%" px="4%" borderRadius={10} alignItems="center" justifyContent="space-around" flexWrap="wrap">
                <Flex minWidth="280px">
                    <Text width="40%" maxWidth="40%">{start.slice(0, 10).replaceAll('-', '.')}.</Text>
                    <Text width="20%" maxWidth="20%">{start.slice(11, 16)}</Text>
                    <Text width="20%" maxWidth="20%">{duration + " óra"}</Text>
                    <Text width="20%" maxWidth="20%">{peopleCount + " fő"}</Text>
                </Flex>
                <ActionButtons email={user.email} isUserReservation={isUserReservation} userId={userId}
                    role={role} accountId={accountId} state={state} id={id} onStateChange={handleStateChange} />
            </Flex>
        )
        )
    }

    // eslint-disable-next-line
    useEffect(() => { updateDisplayComponent(); }, [reservationState]);

    return displayComponent;
};
