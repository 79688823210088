import React, { useState } from "react";
import { FileUploader } from "react-drag-drop-files";

const fileTypes = ["JPG", "JPEG", "PNG"];

function DragDrop({onFileChanged}) {
    //eslint disable next line
  const [file, setFile] = useState(null);
  const handleChange = (file) => {
    setFile(file);
    onFileChanged(file);
  };
  return (
    <FileUploader handleChange={handleChange} name="file" types={fileTypes} multiple={false} label="Ide töltsd fel a képet a kajáról!" />
  );
}

export default DragDrop;
