class UserService{
    constructor(httpClient){
        this.httpClient = httpClient;
    }

    async getUserData(account){
        try{
            const response = await this.httpClient.get('Users/me');
            return response.json();
        }catch(error){
            console.error(error)
        }
    }

    async getActiveReservationForUser(userId){
        try{
          const response = await this.httpClient.get('Users/myActiveReservation');
          return response.json();
        }catch(error){
          console.error(error)
        }
      }
}

export default UserService;
