import React from 'react';
import {
  FormControl,
  Flex,
  FormLabel,
  Input,
  Select,
  Divider,
} from '@chakra-ui/react';

const TopBarIfUser = ({
  selectedDate,
  minDate,
  selectedDuration,
  setSelectedDate,
  setSelectedDuration,
  selectedPeople,
  setSelectedPeople,
}) => {
  return (
    <>
      <FormControl>
        <Flex
          alignItems="center"
          flexWrap={{ base: 'wrap', md: 'nowrap' }}
        >
          {/* DatePicker, aktuális dátummal feltöltve. Változást leköveti*/}
          <Flex flex={{ base: '1', md: 'unset' }} alignItems="center">
            <FormLabel whiteSpace="nowrap" m={2}>
              Foglalás kezdete:
            </FormLabel>
            <Input
              placeholder="Select Date and Time"
              size="md"
              type="datetime-local"
              defaultValue={selectedDate}
              min={minDate}
              onChange={(e) => setSelectedDate(e.target.value)}
              w={{ base: '100%', md: '200px' }}
              m={2}
            />
          </Flex>
          {/* Időtartam kiválasztó. Változást leköveti*/}
          <Flex flex={{ base: '1', md: 'unset' }} alignItems="center">
            <FormLabel m={2}>Időtartam:</FormLabel>
            <Select
              value={selectedDuration}
              onChange={(e) => setSelectedDuration(e.target.value)}
              w="90px"
            >
              <option value="1">1 óra</option>
              <option value="2">2 óra</option>
              <option value="3">3 óra</option>
            </Select>
          </Flex>
          {/* "Vendég-szám" kiválasztó. Változást leköveti*/}
          <Flex flex={{ base: '1', md: 'unset' }} alignItems="center">
            <FormLabel whiteSpace="nowrap" m={2}>
              Emberek száma:
            </FormLabel>
            {/* kis bruteforce*/}
            <Select
              value={selectedPeople}
              onChange={(e) => setSelectedPeople(parseInt(e.target.value))}
              w="90px"
              m={2}
            >
              {[...Array(10).keys()].map((num) => (
                <option key={num + 1} value={num + 1}>
                  {num + 1}
                </option>
              ))}
            </Select>
          </Flex>
        </Flex>
      </FormControl>
      <Divider />
    </>
  );
};

export default TopBarIfUser;
