class ProductService{
  constructor(httpClient){
    this.httpClient = httpClient;
  }

  async getProducts(){
    try{
      const response = await this.httpClient.get('Categories/WithProducts');
      return response.json();
    }catch(error){
      console.error(error)
    }
  }

  async createProduct(product){
    try{
      await this.httpClient.postImage('Products', product);
    }catch(error){
      console.error(error)
    }
  }

  async updateProduct(id, product){
    try{
      await this.httpClient.putImage('Products/' + id, product);
    }catch(error){
      console.error(error)
    }
  }

  async destroyProduct(productId){
    try{
      await this.httpClient.destroy('Products/' + productId);
    }catch(error){
      console.error(error)
    }
  }
}

export default ProductService;