import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  FormControl,
  FormLabel,
  Input,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  NumberIncrementStepper,
  NumberDecrementStepper,
  Text,
  useToast
} from '@chakra-ui/react';

import React, { useState } from 'react';
import FetchClient from '../../serviceclients/fetchclient';
import TableService from '../../services/tableservice';
import { useAuth } from '../../providers/authProvider';

function timeout(delay) {
  return new Promise((res) => setTimeout(res, delay));
}

export default function NewTableForm({ closeModal, isModalOpen, onTableAdded }) {
  const [capacity, setCapacity] = useState(0);
  const [columnStart, setColumnStart] = useState(0);
  const [columnEnd, setColumnEnd] = useState(0);
  const [rowStart, setRowStart] = useState(0);
  const [rowEnd, setRowEnd] = useState(0);

  const authContext = useAuth();

  // Beviteli gondok
  const [emptyCapacityError, setEmptyCapacityError] = React.useState(false)
  const [invalidColumnStartError, setInvalidColumnStartError] = React.useState(false)
  const [invalidColumnEndError, setInvalidColumnEndError] = React.useState(false)
  const [invalidRowStartError, setInvalidRowStartError] = React.useState(false)
  const [invalidRowEndError, setInvalidRowEndError] = React.useState(false)

  const [invalidRowsError, setInvalidRowsError] = React.useState(false)
  const [invalidColumnsError, setInvalidColumnsError] = React.useState(false)

  const toast = useToast()

  const submitFunction = async (event) => {
    event.preventDefault();
    const ok = valid();
    if (ok) {
      await saveFunction();
    }
  };

  const valid = () => {
    let isValid = true;

    if(rowEnd === rowStart){
      setInvalidRowsError(true);
      isValid = false;
    }
    else{
      setInvalidRowsError(false);
    }

    if(columnEnd === columnStart){
      setInvalidColumnsError(true);
      isValid = false;
    }
    else{
      setInvalidColumnsError(false);
    }

    if (capacity < 1 || isNaN(capacity)) {
      setEmptyCapacityError(true);
      isValid = false;
    } else {
      setEmptyCapacityError(false);
    }

    if (columnStart < 1 || columnStart > 8 || isNaN(columnStart)) {
      setInvalidColumnStartError(true);
      isValid = false;
    } else {
      setInvalidColumnStartError(false);
    }

    if (rowStart < 1 || rowStart > 8 || isNaN(rowStart)) {
      setInvalidRowStartError(true);
      isValid = false;
    } else {
      setInvalidRowStartError(false);
    }

    if (columnEnd < 2 || columnEnd > 9 || isNaN(columnEnd)) {
      setInvalidColumnEndError(true);
      isValid = false;
    } else {
      setInvalidColumnEndError(false);
    }

    if (rowEnd < 2 || rowEnd > 9 || isNaN(rowEnd)) {
      setInvalidRowEndError(true);
      isValid = false;
    } else {
      setInvalidRowEndError(false);
    }

    return isValid;
  };

  const showErrorToast = () => {
    toast({
      title: 'A művelet sikertelen volt! A felvett asztal fedi valamelyik meglévő asztalt!',
      status: 'error',
      isClosable: true,
    })
  }

  const showSuccessToast = () => {
    toast({
      title: 'A művelet sikeres!',
      status: 'success',
      isClosable: true,
    });
  }

  const resetValues = async () => {
    setCapacity(0);
    setRowStart(0);
    setRowEnd(0);
    setColumnEnd(0);
    setColumnStart(0);
  };  

  const saveFunction = async () => {
    const tableData = {
      id: 0,
      capacity: capacity,
      columnStart: columnStart,
      columnEnd: columnEnd,
      rowStart: rowStart,
      rowEnd: rowEnd,
    };

    const tableService = new TableService(new FetchClient(authContext));
    try {
      var addedId = await tableService.createTable(tableData);
      if (addedId != -1) {
        tableData.id = addedId
        closeDialog();
        onTableAdded(tableData);
        showSuccessToast();
      }
      else {
        showErrorToast();
      }
    } catch (error) {
      console.log(error);
    }
  };

  const closeDialog = () => {
    closeModal();
    resetValues();
  }

  return (
    <>
      <Modal isOpen={isModalOpen} onClose={closeModal}>
        <ModalOverlay />
        <ModalContent width="90%">
          <form onSubmit={submitFunction}>
            <ModalHeader>Új asztal felvétele</ModalHeader>
            <ModalCloseButton />
            <ModalBody pb={6}>
              <FormControl mt={4}>
                <FormLabel>Kapacitás</FormLabel>
                <NumberInput>
                  <NumberInputField onChange={(event) => {setCapacity(parseInt(event.target.value))}} />
                </NumberInput>
                {emptyCapacityError ? <Text textColor="red.400" fontSize="sm" p="1" pl="2">
                  Nem lehet 0 kapacitása.
                </Text> : null}
              </FormControl>

              <FormControl mt={4}>
                <FormLabel>Kezdősor</FormLabel>
                <NumberInput>
                  <NumberInputField onChange={(event) => setRowStart(parseInt(event.target.value))} />
                </NumberInput>
                {invalidRowStartError ? <Text textColor="red.400" fontSize="sm" p="1" pl="2">
                  A kezdősor 1 és 8 közötti értéket vehet fel.
                </Text> : null}

                {invalidRowsError ? <Text textColor="red.400" fontSize="sm" p="1" pl="2">
                  A kezdősor és a végsor nem egyezhetnek meg.
                </Text> : null}
              </FormControl>

              <FormControl mt={4}>
                <FormLabel>Kezdőoszlop</FormLabel>
                <NumberInput>
                  <NumberInputField onChange={(event) => setColumnStart(parseInt(event.target.value))} />
                </NumberInput>
                {invalidColumnStartError ? <Text textColor="red.400" fontSize="sm" p="1" pl="2">
                  A kezdőoszlop 1 és 8 közötti értéket vehet fel.
                </Text> : null}
                
                {invalidColumnsError ? <Text textColor="red.400" fontSize="sm" p="1" pl="2">
                  A kezdőoszlop és a végoszlop nem egyezhetnek meg.
                </Text> : null}
              </FormControl>

              <FormControl mt={4}>
                <FormLabel>Végsor</FormLabel>
                <NumberInput>
                  <NumberInputField onChange={(event) => setRowEnd(parseInt(event.target.value))} />
                </NumberInput>
                {invalidRowEndError ? <Text textColor="red.400" fontSize="sm" p="1" pl="2">
                  A végsor 2 és 9 közötti értéket vehet fel.
                </Text> : null}

                {invalidRowsError ? <Text textColor="red.400" fontSize="sm" p="1" pl="2">
                  A kezdősor és a végsor nem egyezhetnek meg.
                </Text> : null}
              </FormControl>

              <FormControl mt={4}>
                <FormLabel>Végoszlop</FormLabel>
                <NumberInput>
                  <NumberInputField onChange={(event) => setColumnEnd(parseInt(event.target.value))} />
                </NumberInput>
                {invalidColumnEndError ? <Text textColor="red.400" fontSize="sm" p="1" pl="2">
                  A végoszlop 2 és 9 közötti értéket vehet fel.
                </Text> : null}
                
                {invalidColumnsError ? <Text textColor="red.400" fontSize="sm" p="1" pl="2">
                  A kezdőoszlop és a végoszlop nem egyezhetnek meg.
                </Text> : null}
              </FormControl>
            </ModalBody>

            <ModalFooter>
              <Button colorScheme="teal" mr={3} type="submit">
                Mentés
              </Button>
              <Button variant="ghost" onClick={closeDialog}>
                Mégse
              </Button>
            </ModalFooter>
          </form>
        </ModalContent>
      </Modal>
    </>
  );
}