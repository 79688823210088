import React, { useState } from 'react';
import {
  AlertDialog, AlertDialogBody,
  AlertDialogCloseButton,
  AlertDialogContent, AlertDialogFooter,
  AlertDialogHeader,
  Button
} from '@chakra-ui/react';
import RatingService from '../../services/ratingservice';
import FetchClient from '../../serviceclients/fetchclient';
import { useAuth } from '../../providers/authProvider';

export default function SaveRatingButton({ closeDialog, isDialogOpen, text, rating, user}) {
  const cancelRef = React.useRef()

  const [error, setError] = useState(null);
  const authContext = useAuth();

  const submitFunction = (event) => {
    event.preventDefault();
    saveFunction(event);
  }

  const saveFunction = (event) => {

    const ratingService = new RatingService(new FetchClient(authContext));
    const postRating = async () => {
      if(text===undefined||text.length===0){
        setError("Nem lehet üres a vélemény szövege.")
      }else{
        var response;
        try {
        response = await ratingService.createRating({
                                                          id: 0,
                                                          szoveg: text,
                                                          csillag: rating,
                                                          userId: user });
          if ( response && response.status === 400 ) {
            setError("Hiba! A véleményezés nem sikerült, próbálkozzon újra.");
          }else{
            window.location.reload();
          }
        } catch (error) {
          console.log(error);
          }
        }
      }
      
    postRating();
    closeDialog();
  }

  return (
    <>
      <AlertDialog
        motionPreset='slideInBottom'
        leastDestructiveRef={cancelRef}
        onClose={closeDialog}
        isOpen={isDialogOpen}
        isCentered
      >
        <AlertDialogContent width="90%">
          <AlertDialogHeader>Menti a véleményt?</AlertDialogHeader>
          <AlertDialogCloseButton />
          <AlertDialogBody>
            Biztosan ezt akarta írni csodás kis éttermünkről?!?!
          </AlertDialogBody>
          <AlertDialogFooter>
            <Button ref={cancelRef} onClick={closeDialog}>
              Nem
            </Button>
            <form onSubmit={submitFunction}>
              <Button colorScheme='green' ml={3} type="submit" >
                Igen
              </Button>
            </form>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>

      {error && (
        <div style={{ background: 'red', color: 'white', padding: '10px', position: 'fixed', top: '0', left: '0', right: '0', zIndex: '9999', textAlign: 'center' }}>
          {error}
        </div>
      )}
    </>
  )
}