import React, { useEffect, useState } from 'react';
import {
  VStack,
  Center,
  Button,
  Grid,
  GridItem,
  useToast
} from '@chakra-ui/react';
import FetchClient from '../serviceclients/fetchclient.js';
import Spinner from '../components/connection/spinner.js';
import NetworkError from '../components/connection/network_error.js';
import TableService from '../services/tableservice.js';
import SaveReservationButton from '../components/reservation/saveReservationButtonDialog';
import LoginToSee from './loginToSee.js';
import ReservationService from '../services/reservationservice';
import { useAuth } from '../providers/authProvider.jsx';
import NewTableForm from '../components/reservation/newTableForm.js';
import TableListItem from '../components/reservation/tableListItem.js';
import { getColorForTable, valid, showErrorToast, showSuccessToast} from '../components/reservation/ReservationLogic.js';
import {
  MdAdd
} from "react-icons/md"
import TopBarIfUser from '../components/reservation/TopBarIfUser.js';

function timeout(delay) {
  return new Promise(res => setTimeout(res, delay));
}

const Reservation = ({ role, accountId }) => {
  // A datePicker-t az aktualis idovel toltom fel
  const currentDate = new Date();
  const offsetMinutes = currentDate.getTimezoneOffset(); // Get the time zone offset in minutes
  currentDate.setMinutes(currentDate.getMinutes() - offsetMinutes); // Adjust for the time zone offset
  const formattedCurrentDate = currentDate.toISOString().slice(0, 16); // Use adjusted date
  //for default value in the date picker
  const minDate = formattedCurrentDate;

  const authContext = useAuth();
  const tableService = new TableService(new FetchClient(authContext));

  const getColor = (table) => getColorForTable(table, role, selectedPeople, selectedDate, selectedDuration, reservations);

  const [tables, setTables] = useState([]);
  const [isLoaded, setIsLoaded] = useState(false);
  const [selectedPeople, setSelectedPeople] = useState(1);
  const [selectedDuration, setSelectedDuration] = useState(1);
  const [selectedTableId, setSelectedTableId] = useState(null);
  const [selectedDate, setSelectedDate] = useState(formattedCurrentDate);

  const doFetch = async () => {
    setIsLoaded(false);
    await timeout(1000);
    const fetchTables = async () => {
      try {
        const tableses = await tableService.getTables();
        setTables(tableses);
        setIsLoaded(true);
      } catch (error) {
        console.log(error);
      }
    };
    fetchTables();
  };

  // létszám és kapacitás alapján letiltja a gombot
  const isButtonEnabled = (table) => {
    if (role === 1)
      return true;
    //return table.capacity >= selectedPeople;
    var color = getColor(table);
    if (color === 'green.500') {
      return true;
    } else {
      return false;
    }
  };

  const toast = useToast()

  const handleTableChange = (event, field, tableId) => {
    if (event.target.value === "") {
      return;
    }
    var updatedTable = tables.find(table => table.id === tableId);
    // Módosítom az adott asztal adatait
    switch (field) {
      case 'ColumnStart':
        updatedTable.columnStart = parseInt(event.target.value);
        break;
      case 'ColumnEnd':
        updatedTable.columnEnd = parseInt(event.target.value);
        break;
      case 'RowStart':
        updatedTable.rowStart = parseInt(event.target.value);
        break;
      case 'RowEnd':
        updatedTable.rowEnd = parseInt(event.target.value);
        break;
      default:
        break;
    }
    if (valid(updatedTable, toast))
      updateTable(updatedTable)
  }

  const updateTable = async (table) => {
    const isSuccess = await tableService.updateTable(table);

    if (isSuccess) {
      console.log('Sikeres frissítés');
      showSuccessToast(toast)

      setTimeout(() => {
        const updatedTables = tables.map(table =>
          table.id === table.id ? { ...table } : table
        );
        setTables(updatedTables)
      }, 0);
    } else {
      console.log('A frissítés sikertelen');
      showErrorToast(toast)
    }
  }

  useEffect(() => {
    doFetch();
  }, []);

  const [reservations, setReservations] = useState([]);
  const doFetchReservations = async () => {
    setIsLoaded(false);
    await timeout(1000);
    const reservationService = new ReservationService(new FetchClient(authContext));
    const fetchReservations = async () => {
      try {
        const reser = await reservationService.getReservations();
        setReservations(reser);
        setIsLoaded(true);
      } catch (error) {
        console.log(error);
      }
    }
    fetchReservations();
  };

  //gomb/asztal eseménykezelő
  const handleButtonClicked = (table) => {
    if (role === 0 && isButtonEnabled(table)) {
      setSelectedTableId(table.id); // Set the selected table ID
      openModal();
    }
  };

  //lekéri az asztalokat
  //HTTP GET when one of the three data input field is changed
  useEffect(() => {
    doFetchReservations();
  }, [selectedDate, selectedDuration, selectedPeople])

  useEffect(() => {

  }, [tables]);

  const handleDeleteButtonClicked = async (tableId) => {
    var success = await tableService.deleteTable(tableId);

    if(success)
    {
      const updatedTables = tables.filter(t => t.id !== tableId);
      setTables(updatedTables);
    }
    else
    {
      showErrorToast(toast, "Az asztal nem törölhető, mert tartoznak hozzá foglalások!")
    }
  };

  const [isModalOpen, setIsModalOpen] = useState(false);
  const openModal = () => {
    setIsModalOpen(true);
  };
  const closeModal = () => {
    setIsModalOpen(false);
  };

  if (role === -1) {
    return <LoginToSee />
  } else if (tables === undefined) {
    return <NetworkError />;
  } else if (!isLoaded) {
    return <Spinner />;
  } else {
    return (
      <>
        <Center ml="5" mr="5">
          <VStack mt="10" mb="10">
            {
              role === 0 && (
                <TopBarIfUser
                  selectedDate={selectedDate}
                  minDate={minDate}
                  selectedDuration={selectedDuration}
                  setSelectedDate={setSelectedDate}
                  setSelectedDuration={setSelectedDuration}
                  selectedPeople={selectedPeople}
                  setSelectedPeople={setSelectedPeople}
                />
              )
            }
            <Grid
              display="grid" gridGap={0} border='2px' borderColor='gray.700' borderRadius='8px'
              templateColumns={{ base: "repeat(8, 40px)", md: "repeat(8, 80px)" }} templateRows={{ base: "repeat(8, 40px)", md: "repeat(8, 80px)" }}>
              {tables.map((table) => (
                <GridItem key={table.id} display="flex" gridColumnStart={table.columnStart}
                  gridColumnEnd={table.columnEnd} gridRowStart={table.rowStart} gridRowEnd={table.rowEnd}>
                  <Button
                    w="100%"
                    h="100%"
                    bg={getColor(table)}
                    onClick={() => handleButtonClicked(table)}
                    // Disable the button if it's not enabled
                    isDisabled={!isButtonEnabled(table)}
                  >
                    #{tables.indexOf(table) + 1} {table.capacity} fő
                  </Button>
                  <SaveReservationButton closeDialog={closeModal}
                    isDialogOpen={isModalOpen}
                    time={selectedDate}
                    tableId={selectedTableId}
                    duration={selectedDuration}
                    people={selectedPeople}
                    user={accountId} />
                </GridItem>
              ))}
            </Grid>
            {
              role === 1 &&
              (
                <>
                  <Button colorScheme='teal' leftIcon={<MdAdd />} onClick={openModal}>Új asztal felvétele</Button>
                  <NewTableForm closeModal={closeModal} isModalOpen={isModalOpen} onTableAdded={(newTable) => setTables([...tables, newTable])} />
                  {tables !== undefined && (
                    tables.map((table) => (
                      <TableListItem
                        key={table.id}
                        table={table}
                        handleTableChange={handleTableChange}
                        handleDeleteButtonClicked={handleDeleteButtonClicked}
                      />
                    ))
                  )}
                </>
              )
            }

          </VStack>
        </Center>
      </>
    );
  }
};

export default Reservation;