import React, { useEffect, useState } from 'react';
import {
    Stack,
    Button,
    Link,
    Text
} from '@chakra-ui/react'
import {
    MdDelete,
    MdPayment,
    MdTimer,
    MdCloudDone
} from "react-icons/md"
import ReservationsService from '../../services/reservationsservice';
import FetchClient from '../../serviceclients/fetchclient';
import { Spinner } from '@chakra-ui/react';
import { useAuth } from '../../providers/authProvider';
import { useNavigate } from "react-router-dom";

function timeout(delay) {
    return new Promise(res => setTimeout(res, delay));
}

export default function ActionButtons(props) {
    const buttonStyle = {
        textAlign: "center",
        fontSize: "sm"
    };

    const [state, setState] = useState(props.state)
    const [stateButton, setStateButton] = useState()
    const [deleteButton, setDeleteButton] = useState()
    const [isLoaded, setIsLoaded] = useState(false);

    const authContext = useAuth();
    const navigate = useNavigate();

    const handleStateChange = (newState) => {
        if (props.onStateChange) {
            props.onStateChange(newState);
        }
    };

    const fetchReservationFunction = async () => {
        await timeout(1000);
        const reservationsService = new ReservationsService(new FetchClient(authContext));
        const fetchReservation = async () => {
            try {
                const reservation = await reservationsService.getReservation(props.id);
                setState(reservation.state);
                setIsLoaded(true);
            } catch (error) {
                console.log(error);
            }
        }
        fetchReservation();
    }

    const activateFunction = async (url) => {
        const reservationsService = new ReservationsService(new FetchClient(authContext));
        try {
            await reservationsService.activateReservation(props.id);
            setIsLoaded(false);
            navigate(url);
            window.location.reload();
        } catch (error) {
            console.log(error);
        }
    };

    const hideFunction = async () => {
        const reservationsService = new ReservationsService(new FetchClient(authContext));
        try {
            await reservationsService.hideReservation(props.id);
            setIsLoaded(false);
        } catch (error) {
            console.log(error);
        }
    };

    const destroyFunction = async () => {
        const reservationsService = new ReservationsService(new FetchClient(authContext));
        try {
            await reservationsService.destroyReservation(props.id);
            setState(-1);
            handleStateChange(-1);
        } catch (error) {
            console.log(error);
        }
    };

    const toBasket = "/basket?id=" + props.id;

    function renderButtons() {
        if (props.isUserReservation)
            switch (state) {
                case 0:
                    setStateButton(
                            <Button style={buttonStyle} leftIcon={<MdTimer />} colorScheme='orange' variant='outline' onClick={() => activateFunction('/products')}>
                                Rendelés megkezdése
                            </Button>
                    )
                    setDeleteButton(
                        <Button style={buttonStyle} leftIcon={<MdDelete />} colorScheme='red' variant='outline' fontSize="sm" onClick={() => destroyFunction()}>
                            Törlés
                        </Button>
                    )
                    break;
                case 1:
                    setStateButton(
                        <Link href={toBasket} >
                            <Button style={buttonStyle} leftIcon={<MdPayment />} colorScheme='green' variant='outline' >
                                Számla
                            </Button>
                        </Link>
                    )
                    setDeleteButton(null)
                    break;
                case 2:
                    setStateButton(
                        <Link href={toBasket} >
                            <Button style={buttonStyle} leftIcon={<MdPayment />} colorScheme='green' variant='outline' >
                                Számla
                            </Button>
                        </Link>
                    )
                    setDeleteButton(
                        <Button style={buttonStyle} leftIcon={<MdCloudDone />} colorScheme='cyan' variant='outline' onClick={() => hideFunction()}>
                            Elrejtés
                        </Button>
                    )
                    break;
                case 3:
                case -1:
                default:
                    setStateButton(null)
                    setDeleteButton(null)
                    break;
            }
        else {
            setStateButton(
                <Text pt="1">
                    {props.email}
                </Text>
            )
            var isButtonDisabled = true
            if (state === 0)
                isButtonDisabled = false
            setDeleteButton(
                <Button isTruncated isDisabled={isButtonDisabled} style={buttonStyle} leftIcon={<MdDelete />} colorScheme='red' variant='outline' fontSize="sm" onClick={() => destroyFunction()}>
                    Törlés
                </Button>
            )
        }
    }

    // eslint-disable-next-line
    useEffect(() => {
        if (isLoaded === false)
            fetchReservationFunction()
        else {
            renderButtons()
            handleStateChange(state)
        }
        // eslint-disable-next-line
    }, [isLoaded]);

    return isLoaded === true ?
        <Stack direction='row' mt="2">
            {stateButton}
            {deleteButton}
        </Stack>
        : <Spinner />;
}