class CategoryService{
    constructor(httpClient){
        this.httpClient = httpClient;
    }

    async getCategories(){
        try{
            const response = await this.httpClient.get('Categories');
            return response.json();
        }catch(error){
            console.error(error)
        }
    }

    async createCategory(category){
        try{
            const response = await this.httpClient.post('Categories', category);
            return response.json();
        }catch(error){
            console.error(error)
        }
    }

    async updateCategory(category){
        try{
            await this.httpClient.put('Categories/' + category.id, category);
        }catch(error){
            console.error(error)
        }
    }

    async destroyCategory(categoryId){
        try{
            await this.httpClient.destroy('Categories/' + categoryId);
        }catch(error){
            console.error(error)
        }
    }
}

export default CategoryService;