import React from "react";
import { Button } from "@chakra-ui/react";
import { useMsal } from "@azure/msal-react";
import b2cPolicies from "../../config/policies";

/**
 * Renders a sign out button 
 */
export const EditProfileButton = ({username}) => {
  const { instance } = useMsal();
  
  const editProfile = () => {
    const editProfileRequest = b2cPolicies.authorities.editProfile;
    editProfileRequest.loginHint = username;

    instance.loginRedirect(editProfileRequest);
  }

  return (
    <Button fontSize={18} colorScheme='yellow' p="4" mt="10" alignSelf="center" onClick={() => editProfile()}>Profilom szerkesztése</Button>
  );
};
