export default class OrderService{
    constructor(httpClient){
        this.httpClient = httpClient;
    }

    async getStatisticsTotalIncome(){
        try{
            const response = await this.httpClient.get('Orders/GetStatistics');
            return response.json();
        }catch(error){
            console.error(error)
        }
    }

    async getOrders(reservationId){
        try{
          const response = await this.httpClient.get('Orders/' + reservationId);
          return response.json();
        }catch(error){
          console.error(error)
        }
      }
    
      async createOrder(order){
        try{
          const response = await this.httpClient.post('Orders', order);
          return response.json();
        }catch(error){
          console.error(error)
        }
      }
    
      async updateOrder(order){
        try{
          await this.httpClient.put('Orders/' + order.id, order);
        }catch(error){
          console.error(error)
        }
      }
    
      async destroyOrder(orderId){
        try{
          await this.httpClient.destroy('Orders/' + orderId);
        }catch(error){
          console.error(error)
        }
      }
}