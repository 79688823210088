import b2cPolicies from './policies.js'
import apiConfig from './apiConfig.js'

export const msalConfig = {
    auth: {
      clientId: "59b6e97a-ad9b-49b6-87c7-58255f078235", // This is the ONLY mandatory field; everything else is optional.
      authority: b2cPolicies.authorities.signUpSignIn.authority, // Choose sign-up/sign-in user-flow as your default.
      knownAuthorities: [b2cPolicies.authorityDomain], // You must identify your tenant's domain as a known authority.
      redirectUri: "https://ettermunk.andai.hu", // You must register this URI on Azure Portal/App Registration. Defaults to "window.location.href".
    },
    cache: {
      cacheLocation: "localStorage",  
      storeAuthStateInCookie: true, 
    },
    system: {
      loggerOptions: {
        loggerCallback: (level, message, containsPii) => {
          if (containsPii) {
            return;
          }
          return;
        }
      }
    }
  };

export const loginRequest = {
  scopes: ["openid", ...apiConfig.b2cScopes],
};

export const tokenRequest = {
  scopes: [...apiConfig.b2cScopes],  // e.g. ["https://fabrikamb2c.onmicrosoft.com/helloapi/demo.read"]
  forceRefresh: false // Set this to "true" to skip a cached token and go to the server to get a new token
};
