import React, { useState } from 'react';
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
  ButtonGroup,
  Flex,
  HStack,
  Image,
  Text,
  VStack,
  useDisclosure,
} from '@chakra-ui/react';
import cowImage from '../../resources/cow.png';
import { MdDelete } from 'react-icons/md';
import FetchClient from '../../serviceclients/fetchclient';
import apiConfig from '../../config/apiConfig.js'
import OrderService from '../../services/orderservice.js';
import { useAuth } from '../../providers/authProvider.jsx';

export default function ListItem({order, onChange, actual}){
  const authContext = useAuth();

    const updateFunction = (qty) => {
        const orderService = new OrderService(new FetchClient(authContext));
        const updateOrder = async () => {
          try{
            await orderService.updateOrder({id: order.id, quantity: qty, reservationId: order.reservationId, productId: order.productId});
          }catch(error){
            console.log(error);
          }
        }
        updateOrder();
        onChange();
      };

  const deleteFunction = () => {
    const orderService = new OrderService(new FetchClient(authContext));
    const destroyOrder = async () => {
      try{
        await orderService.destroyOrder(order.id);
      }catch(error){
        console.log(error);
      }
    }
    destroyOrder();
    onChange();
  };
  const DeleteConfirmation = () => {
    const { isOpen, onOpen, onClose } = useDisclosure()
    const cancelRef = React.useRef()

    return (
      <>
        <Button colorScheme='red' variant='outline' fontSize="sm" onClick={onOpen}>{<MdDelete/>}</Button>

        <AlertDialog
          isOpen={isOpen}
          leastDestructiveRef={cancelRef}
          onClose={onClose}
        >
          <AlertDialogOverlay>
            <AlertDialogContent width="90%">
              <AlertDialogHeader fontSize='lg' fontWeight='bold'>Tétel törlése</AlertDialogHeader>

              <AlertDialogBody>
                Biztosan törölni szeretnéd? Ezt a műveletet nem tudod visszavonni.
              </AlertDialogBody>

              <AlertDialogFooter>
                <Button ref={cancelRef} onClick={onClose}>
                  Mégse
                </Button>
                <Button colorScheme='red' onClick={() => deleteFunction()} ml={3}>
                  Törlés
                </Button>
              </AlertDialogFooter>
            </AlertDialogContent>
          </AlertDialogOverlay>
        </AlertDialog>
      </>
    )
  }

  const kep = order.product.imageFilePath==="" ? cowImage : apiConfig.imageroute+order.product.imageFilePath;

  /* Egy listaelemet rak össze, kap 1 product-ot és annak az adatait
      berakja egy flex-be*/
  return (
    <Flex flexWrap="wrap" fontSize="sm" bg='lightgray' w='100%' p={4} borderRadius={10} alignItems="center" justifyContent="space-around" mb='2'>
      <HStack>
        <Image m="1" src={kep} alt="image" maxHeight="80px" />
        <Text m="1" fontWeight="bold">{order.product.megnevezes}</Text>
      </HStack>
      <HStack>
        <Text m="1">{order.product.ar} Ft</Text>
        <QuantityInput valueparam={order.quantity} onQtyChange={(qty) => updateFunction(qty)} actual={actual} />
        <Text m="1" fontWeight="bold">{order.quantity*order.product.ar} Ft</Text>
        {actual && (<ButtonGroup spacing="4" my="4">
            <DeleteConfirmation/>
        </ButtonGroup>)}
      </HStack>
    </Flex>
  )
}

function QuantityInput({ valueparam, onQtyChange, actual }) {
    const [value, setValue] = useState(valueparam);
  
    const handleIncrement = () => {
      if (value < 9) {
        const newValue = value + 1;
        setValue(newValue);
        onQtyChange(newValue);
      }
    };
  
    const handleDecrement = () => {
      if (value > 1) {
        const newValue = value - 1;
        setValue(newValue);
        onQtyChange(newValue);
      }
    };
  
    return (
      <VStack maxW='140px'>
        {actual && (<Button onClick={handleIncrement}>+</Button>)}
        <Text m="1">{value} db</Text>
        {actual && (<Button onClick={handleDecrement}>-</Button>)}
      </VStack>
    );
}  
