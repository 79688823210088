export default class ReservationsService{
    constructor(httpClient){
        this.httpClient = httpClient;
    }

    async getReservations(){
        try{
            const response = await this.httpClient.get('Reservations');
            return response.json();
        }catch(error){
            console.error(error)
        }
    }

    async getReservationsForAdmin(accountId){
        try{
            const response = await this.httpClient.get('Reservations/GetReservationsForAdmin');
            return response.json();
        }catch(error){
            console.error(error)
        }
    }

    async getReservationsForUser(accountId){
        try{
            const response = await this.httpClient.get('Reservations/GetMyReservations');
            return response.json();
        }catch(error){
            console.error(error)
        }
    }

    async getReservation(reservationId){
        try{
            const response = await this.httpClient.get('Reservations/' + reservationId);
            return response.json();
        }catch(error){
            console.error(error)
        }
    }

    async activateReservation(reservationId){
        try{
            await this.httpClient.get('Reservations/Activate/' + reservationId);
        }catch(error){
            console.error(error)
        }
    }

    async finishReservation(reservationId){
        try{
            await this.httpClient.get('Reservations/Finish/' + reservationId);
        }catch(error){
            console.error(error)
        }
    }

    async hideReservation(reservationId){
        try{
            await this.httpClient.get('Reservations/Hide/' + reservationId);
        }catch(error){
            console.error(error)
        }
    }

    async destroyReservation(reservationId){
        try{
            await this.httpClient.destroy('Reservations/' + reservationId);
        }catch(error){
            console.error(error)
        }
    }
}