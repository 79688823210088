import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button, FormControl, FormLabel, Input,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  NumberIncrementStepper,
  NumberDecrementStepper, InputGroup, InputRightAddon, Text, Image
} from '@chakra-ui/react';

import React, { useState } from 'react';
import FetchClient from '../../serviceclients/fetchclient';
import ProductService from '../../services/productservice';
import DragDrop from './dragDrop.jsx'
import apiConfig from '../../config/apiConfig.js'
import cowImage from '../../resources/cow.png';
import { useAuth } from '../../providers/authProvider.jsx';

export default function ProductsEditForm ({ closeModal, isModalOpen, prod, onChange }){

  const [prodName, setProdName ] = useState(prod.megnevezes);
  const [prodPrice, setProdPrice ] = useState(prod.ar);
  const [emptyError, setEmptyError] = React.useState(false)
  const [lengthError, setLengthError] = React.useState(false)
  const [priceError, setPriceError] = React.useState(false)

  const [imageFile, setImageFile] = useState(null);
  const authContext = useAuth();

  const handleDragAndDrop = (fileparam) =>{
    setImageFile(fileparam);
    
    const previewImage = document.getElementById('preview');
    if (previewImage) {
      const reader = new FileReader();
      reader.onload = (e) => {
        previewImage.src = e.target.result;
      };

      reader.readAsDataURL(fileparam);
    }
  }

  const submitFunction = (event) => {
    event.preventDefault();
    const ok = valid();
    if(ok){
      saveFunction(event);
    }
  }

  const valid = () => {
    var isValid = true;

    if (prodPrice === ""){
      setPriceError(true);
      isValid = false;
    } else {
      setPriceError(false);
    }

    if(prodName.length > 15){
      setLengthError(true);
      setEmptyError(false);
      isValid = false;
    } else if(prodName.length === 0){
      setEmptyError(true);
      setLengthError(false);
      isValid = false;
    } else{
      setLengthError(false);
      setEmptyError(false);
    }
    return isValid;
  }

  const saveFunction = (event) => {
    const formData = new FormData();
    formData.append('id', prod.id);
    formData.append('megnevezes', prodName);
    formData.append('ar', prodPrice);
    formData.append('categoryId', prod.categoryId);
    formData.append('imageFilePath', prod.imageFilePath);
    formData.append('imageFile', imageFile);

    const productService = new ProductService(new FetchClient(authContext));
    const putProduct = async () => {
      try{
        await productService.updateProduct(prod.id, formData);
      }catch(error){
        console.log(error);
      }
    }
    putProduct();
    closeModal();
    onChange();
  }

  const kep = prod.imageFilePath==="" ? cowImage : apiConfig.imageroute+prod.imageFilePath;

  return (
    <>
      <Modal isOpen={isModalOpen} onClose={closeModal}>
        <ModalOverlay />
        <ModalContent width="90%">
          <form onSubmit={submitFunction} encType="multipart/form-data">
            <ModalHeader>Termék szerkesztése</ModalHeader>
            <ModalCloseButton />
            <ModalBody pb={6}>

              <FormControl>
                <FormLabel>Új kép feltöltése</FormLabel>
                <DragDrop onFileChanged={handleDragAndDrop} />
                <Image id="preview" my="4" src={kep}/>
              </FormControl>
              
              <FormControl mt={4}>
                <FormLabel>Étel megnevezésének szerkesztése</FormLabel>
                <Input placeholder='Étel megnevezése' defaultValue={prodName}
                       onChange={(event)=>setProdName(event.target.value)}/>
                { emptyError ? <Text textColor="red.400" fontSize="sm" p="1" pl="2">Nem lehet üres a név.</Text> : null }
                { lengthError ? <Text textColor="red.400" fontSize="sm" p="1" pl="2">Nem lehet 15 karakternél hosszabb a név.</Text> : null }
              </FormControl>

              <FormControl mt={4}>
                <FormLabel>Ár szerkesztése</FormLabel>
                <InputGroup>
                  <NumberInput defaultValue={prodPrice}>
                    <NumberInputField onChange={(event)=>setProdPrice(event.target.value)}/>
                    <NumberInputStepper>
                      <NumberIncrementStepper />
                      <NumberDecrementStepper />
                    </NumberInputStepper>
                  </NumberInput>
                  <InputRightAddon children='Ft' />
                </InputGroup>
                { priceError ? <Text textColor="red.400" fontSize="sm" p="1" pl="2">De nem ingyen halo.</Text> : null }
              </FormControl>

            </ModalBody>

            <ModalFooter>
              <Button colorScheme='blue' mr={3} type='submit'>
                Mentés
              </Button>
              <Button variant='ghost' onClick={closeModal}>
                Mégse
              </Button>
            </ModalFooter>
          </form>
        </ModalContent>
      </Modal>
    </>
  )

}

