import React, { useEffect, useState } from 'react';
import {
  ChakraProvider,
  theme,
  Center
} from '@chakra-ui/react';
import MyNavbar from './components/navbar/index.js';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from './pages/home.js'
import Reservation from './pages/reservation.js'
import Reservations from './pages/reservations.js'
import Products from './pages/products.js'
import Ratings from './pages/ratings.js'
import Gallery from './pages/gallery.js'
import Statistics from './pages/statistics.js'
import Categories from './pages/categories.js'
import { ProfileData } from './components/profile/profile.jsx';
import AccessForbidden from './pages/accessforbidden.js';
import NoPage from './pages/nopage.js';
import FetchClient from './serviceclients/fetchclient.js';
import { useMsal } from '@azure/msal-react';
import UserService from './services/userservice.js';
import Spinner from './components/connection/spinner.js'
import Basket from './pages/basket.js';
import { useAuth } from './providers/authProvider.jsx';
import { useIsAuthenticated } from '@azure/msal-react';
import { tokenRequest, loginRequest } from './config/authConfig.js';

function App() {
  const [userData, setUserData] = useState(undefined);
	const [isLoaded, setIsLoaded] = useState(false);
  const [tokenSaved, setTokenSaved] = useState(false);
  const authContext = useAuth();

  const { instance, accounts } = useMsal();
  const { saveToken, getToken } = useAuth(); 
  const isAuthenticated = useIsAuthenticated();

  function RequestProfileData() {
      const existingToken = getToken();
      if (!existingToken&&accounts[0]) {
          // If no token is saved, acquire a new one
          instance
              .acquireTokenSilent({
                  ...tokenRequest,
                  account: accounts[0],
              })
              .then((token) => {
                  // Save the token only if it's not already saved
                  if (!existingToken) {
                     saveToken(token);
                     setTokenSaved(true);
                  }
              })
              .catch((error) => {
                  // Handle errors
                  console.error("Error acquiring token:", error);
                  instance.loginRedirect(loginRequest)
                          .catch((error) => {
                            console.error("Error during interactive login:", error);
                          });
              });
      }
  }

  const doFetch = async () => {
		setIsLoaded(false);
		const userService = new UserService(new FetchClient(authContext));
		const fetchUserData = async ()=>{
			try{
        if(accounts[0]!==undefined){
          const usrdta = await userService.getUserData(accounts[0]);
          const act = await userService.getActiveReservationForUser(usrdta.id);
          if(act==='undefined'){
            usrdta.activeResId = 0;
          }else{
            usrdta.activeResId = act.id;
          }
          setUserData(usrdta);          
        }
        setIsLoaded(true);
			}catch(error){
				console.log(error);
			}
		}
		fetchUserData();
	}

  useEffect(() => {
    const fetchData = async () => {
      if (isAuthenticated && accounts[0] !== undefined) {
        RequestProfileData();
      } else {
        doFetch();
      }
    };

    fetchData();
  // eslint-disable-next-line
  }, [accounts, isAuthenticated, tokenSaved, instance, getToken, saveToken]);

  useEffect(() => {
    if (tokenSaved) {
      doFetch();
    }
  // eslint-disable-next-line
  }, [tokenSaved]);

  if(!isLoaded){
    return <ChakraProvider theme={theme}><Center><Spinner/></Center></ChakraProvider>;
  }else{
    var role;
    var accountId = -1;
    var actId = 0;
    var reservations = [];
    if(accounts[0]===undefined){
      role = -1;
      accountId = -1;
    }
    if(userData!==undefined){
      role = userData.role;
      accountId = userData.id;
      actId = userData.activeResId;
      reservations = userData.reservations.map(r => r.id);
    }
    return (
      <ChakraProvider theme={theme}>
        <Router>
          <MyNavbar role={role} reservationId={actId} isAuthenticated={isAuthenticated} accounts={accounts} />
          <Routes>
            <Route path='/' exact element={<Home/>} />
            <Route path='/reservation' element={<Reservation role={role} accountId={accountId}/>} />
            <Route path='/reservations' element={<Reservations role={role} accountId={accountId}/>} />
            <Route path='/products' element={<Products role={role} reservationId={actId}/>} />
            <Route path='/ratings' element={<Ratings role={role} accountId={accountId}/>} />
            <Route path='/gallery' element={<Gallery/>} />
            <Route path='/statistics' element={<Statistics/>} />
            <Route path='/categories' element={<Categories role={role}/>} />
            <Route path='/profile' element={<ProfileData/>} />
            <Route path="/forbidden" element={<AccessForbidden />} />
            <Route path="/basket" element={<Basket actId={actId} reservations={reservations}/>} />
            <Route path="*" element={<NoPage />} />
          </Routes>
        </Router>
      </ChakraProvider>
    );
  }
}

export default App;
