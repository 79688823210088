import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button, FormControl, FormLabel, Input, Select,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  NumberIncrementStepper,
  NumberDecrementStepper, InputGroup, InputRightAddon, Text,
  Image
} from '@chakra-ui/react';

import React, { useEffect, useState } from 'react';
import CategoryService from '../../services/categoryservice';
import FetchClient from '../../serviceclients/fetchclient';
import NetworkError from '../connection/network_error';
import ProductService from '../../services/productservice';
import DragDrop from './dragDrop';
import { useAuth } from '../../providers/authProvider';

function timeout(delay) {
  return new Promise( res => setTimeout(res, delay) );
}

export default function ProductsForm({ closeModal, isModalOpen, onChange}) {

  const [categories, setCategories] = useState([]);
  const [prodName, setProdName ] = useState('');
  const [prodCat, setProdCat ] = useState('');
  const [prodPrice, setProdPrice ] = useState('');
  const [emptyError, setEmptyError] = React.useState(false)
  const [lengthError, setLengthError] = React.useState(false)
  const [catError, setCatError] = React.useState(false)
  const [priceError, setPriceError] = React.useState(false)

  const [imageFile, setImageFile] = useState(null);
  const authContext = useAuth();

  const handleDragAndDrop = (fileparam) =>{
    setImageFile(fileparam);

    const previewImage = document.getElementById('preview');
    if (previewImage) {
      const reader = new FileReader();
      reader.onload = (e) => {
        previewImage.src = e.target.result;
      };

      reader.readAsDataURL(fileparam);
    }
  }

  const doFetch = async () => {
    await timeout(1000);
    const categoryService = new CategoryService(new FetchClient(authContext));
    const fetchCategories = async ()=>{
      try{
        const cats = await categoryService.getCategories();
        setCategories(cats);
      }catch(error){
        console.log(error);
      }
    }
    fetchCategories();
  }

  useEffect(() => {
    doFetch();
  }, []);

  const submitFunction = (event) => {
    event.preventDefault();
    const ok = valid();
    if(ok){
        saveFunction(event);
      }
  }

  const valid = () => {
    var isValid = true;
    if (prodCat === ""){
      setCatError(true);
      isValid = false;
    } else {
      setCatError(false);
    }

    if (prodPrice === ""){
      setPriceError(true);
      isValid = false;
    } else {
      setPriceError(false);
    }

    if(prodName.length > 15){
      setLengthError(true);
      setEmptyError(false);
      isValid = false;
    } else if(prodName.length === 0){
      setEmptyError(true);
      setLengthError(false);
      isValid = false;
    } else{
      setLengthError(false);
      setEmptyError(false);
    }
    return isValid;
  }

  const saveFunction = (event) => {
    const formData = new FormData();
    formData.append('id', 0);
    formData.append('megnevezes', prodName);
    formData.append('ar', prodPrice);
    formData.append('categoryId', prodCat);
    formData.append('imageFile', imageFile);

    const productService = new ProductService(new FetchClient(authContext));
    const postProduct = async () => {
      try{
        await productService.createProduct(formData);
      }catch(error){
        console.log(error);
      }
    }
    postProduct();
    closeModal();
    onChange();
  }

  if(categories===undefined){
    return <NetworkError />
  }else{
    return (
      <>
        <Modal isOpen={isModalOpen} onClose={closeModal}>
          <ModalOverlay />
          <ModalContent width="90%">
            <form onSubmit={submitFunction} encType="multipart/form-data">
              <ModalHeader>Új termék hozzáadása</ModalHeader>
              <ModalCloseButton />
              <ModalBody pb={6}>

                <FormControl>
                  <FormLabel>Kép</FormLabel>
                  <DragDrop onFileChanged={handleDragAndDrop} />
                  <Image id="preview" my="4"/>
                </FormControl>

                <FormControl mt="4">
                  <FormLabel>Kategória</FormLabel>
                  <Select  placeholder='Kategória' onChange={(event)=>setProdCat(event.target.value)}>
                    {categories.map((cat, index) => (
                      <option key={index} value={cat.id}>
                        {cat.name}
                      </option>
                    ))}
                  </Select>
                  { catError ? <Text textColor="red.400" fontSize="sm" p="1" pl="2">Nem választottál kategóriát.</Text> : null }
                </FormControl>

                <FormControl mt={4}>
                  <FormLabel>Étel megnevezése</FormLabel>
                  <Input placeholder='Étel megnevezése' onChange={(event)=>setProdName(event.target.value)}/>
                  { emptyError ? <Text textColor="red.400" fontSize="sm" p="1" pl="2">Nem lehet üres a név.</Text> : null }
                  { lengthError ? <Text textColor="red.400" fontSize="sm" p="1" pl="2">Nem lehet 15 karakternél hosszabb a név.</Text> : null }
                </FormControl>

                <FormControl mt={4}>
                  <FormLabel>Ár</FormLabel>
                  <InputGroup>
                    <NumberInput >
                      <NumberInputField onChange={(event)=>setProdPrice(event.target.value)}/>
                      <NumberInputStepper>
                        <NumberIncrementStepper />
                        <NumberDecrementStepper />
                      </NumberInputStepper>
                    </NumberInput>
                    <InputRightAddon children='Ft' />
                  </InputGroup>
                  { priceError ? <Text textColor="red.400" fontSize="sm" p="1" pl="2">De nem ingyen halo.</Text> : null }
                </FormControl>

              </ModalBody>

              <ModalFooter>
                <Button colorScheme='blue' mr={3} type='submit'>
                  mentés
                </Button>
                <Button variant='ghost' onClick={closeModal}>
                  mégse
                </Button>
              </ModalFooter>
            </form>
          </ModalContent>
        </Modal>
      </>
    )
  }
}
