import React, { useEffect, useState} from 'react';
import { useMediaQuery } from 'usehooks-ts'
import {
	Text,
	VStack,
	HStack,
	Center,
	Image,
	Box
} from '@chakra-ui/react'
import wedding from '../resources/images/home/wedding.png';
import fancy_dish from '../resources/images/home/fancy_dish.png';
import seafood from '../resources/images/gallery/seafood.png';
import boar from '../resources/images/gallery/boar.png';
import idk from '../resources/images/gallery/idk.png';
import party1 from '../resources/images/gallery/party1.png';
import party2 from '../resources/images/gallery/party2.png';
import party3 from '../resources/images/gallery/party3.png';

import restaurant1 from '../resources/images/gallery/restaurant1.png';
import restaurant2 from '../resources/images/gallery/restaurant2.png';
import restaurant3 from '../resources/images/gallery/restaurant3.png';
import restaurant4 from '../resources/images/gallery/restaurant4.png';


const Gallery = () => {
	const isLargerThan1200 = useMediaQuery('(min-width: 1200px)');
	const [interiorImageSrc, setInteriorImageSrc] = useState(restaurant4);
	const [foodImageSrc, setFoodImageSrc] = useState(fancy_dish);
	const [eventImageSrc, setEventImageSrc] = useState(party2);

	useEffect(() => { });// setImageBar(imageBar[3], imageBar[0], imageBar[1], imageBar[2]) }, [imageSrc]

	return (
		<Center>
			<VStack width={isLargerThan1200 ? '50%' : '100%'} spacing={'100'} py="7">
				<Text bg='#B2F5EA' borderRadius={10} width='100%' textAlign='center' py='0.1%' px='0.1%' fontSize='xxx-large' border="2px solid #1EA1A1">Galéria</Text>

				<VStack bg='#B2F5EA' borderRadius={10} border="2px solid #1EA1A1">
					<Text width='100%' textAlign='center' py='0.1%' px='0.1%' fontSize='xxx-large'>Étterem</Text>
					<Image width='100%' textAlign='left' px='0.2%' py='0.2%' src={interiorImageSrc} />
					<HStack width='100%' px='3%' py='1%' spacing='1.86%'>
						<Image width='23.6%' boxShadow="lg" borderRadius="md" src={restaurant4} onClick={() => setInteriorImageSrc(restaurant4)} />
						<Image width='23.6%' boxShadow="lg" borderRadius="md" src={restaurant2} onClick={() => setInteriorImageSrc(restaurant2)} />
						<Image width='23.6%' boxShadow="lg" borderRadius="md" src={restaurant3} onClick={() => setInteriorImageSrc(restaurant3)} />
						<Image width='23.6%' boxShadow="lg" borderRadius="md" src={restaurant1} onClick={() => setInteriorImageSrc(restaurant1)} />
					</HStack>
				</VStack>

				<VStack bg='#B2F5EA' borderRadius={10} border="2px solid #1EA1A1" >
					<Text width='100%' textAlign='center' py='0.1%' px='0.1%' fontSize='xxx-large'>Ételek</Text>
					<Image width='100%' textAlign='left' px='0.2%' py='0.2%' src={foodImageSrc} />
					<HStack width='100%' px='3%' py='1%' spacing='1.86%'>
						<Image width='23.6%' boxShadow="lg" borderRadius="md" src={fancy_dish} onClick={() => setFoodImageSrc(fancy_dish)} />
						<Image width='23.6%' boxShadow="lg" borderRadius="md" src={idk} onClick={() => setFoodImageSrc(idk)} />
						<Image width='23.6%' boxShadow="lg" borderRadius="md" src={seafood} onClick={() => setFoodImageSrc(seafood)} />
						<Image width='23.6%' boxShadow="lg" borderRadius="md" src={boar} onClick={() => setFoodImageSrc(boar)} />
					</HStack>
				</VStack>

				<VStack bg='#B2F5EA' borderRadius={10} border="2px solid #1EA1A1" >
					<Text width='100%' textAlign='center' py='0.1%' px='0.1%' fontSize='xxx-large'>Rendezvények</Text>
					<Image width='100%' textAlign='left' px='1%' src={eventImageSrc} />
					<HStack width='100%' px='3%' py='1%' spacing='1.86%'>
						<Image width='23.6%' boxShadow="lg" borderRadius="md" src={party2} onClick={() => setEventImageSrc(party2)} />
						<Image width='23.6%' boxShadow="lg" borderRadius="md" src={party3} onClick={() => setEventImageSrc(party3)} />
						<Image width='23.6%' boxShadow="lg" borderRadius="md" src={party1} onClick={() => setEventImageSrc(party1)} />
						<Image width='23.6%' boxShadow="lg" borderRadius="md" src={wedding} onClick={() => setEventImageSrc(wedding)} />
					</HStack>
				</VStack>
			</VStack>
		</Center >
	);
};

export default Gallery;
