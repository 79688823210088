import React, { useState } from 'react';
import {
  AlertDialog, AlertDialogBody,
  AlertDialogCloseButton,
  AlertDialogContent, AlertDialogFooter,
  AlertDialogHeader,
  Button
} from '@chakra-ui/react';
import ReservationService from '../../services/reservationservice';
import FetchClient from '../../serviceclients/fetchclient';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../providers/authProvider';

export default function SaveReservationButton({ closeDialog, isDialogOpen, time, tableId, duration, people, user}) {
  const cancelRef = React.useRef()
  const authContext = useAuth();

  const [error, setError] = useState(null);

  const navigate = useNavigate();
  const handleClick = (url) => {
    navigate(url);
  }

  const submitFunction = (event) => {
    event.preventDefault();
    saveFunction(event);
  }

  const saveFunction = (event) => {
    const reservationService = new ReservationService(new FetchClient(authContext));
    const postReservation = async () => {
      var resp;
      try {
        resp = await reservationService.createReservation({
                                                              duration: duration,
                                                              id: 0,
                                                              peopleCount: people,
                                                              start: time,
                                                              state: 0,
                                                              userId: user,
                                                              tableId: tableId});
        if (resp.status === 400) {
          setError("Hiba! A foglalás nem sikerült, próbálkozzon újra.");
        }else{
          handleClick("/reservations");
        }
      } catch (error) {
        console.log(error);
      }
    }
    postReservation();
    closeDialog();
  }


  return (
    <>
      <AlertDialog
        motionPreset='slideInBottom'
        leastDestructiveRef={cancelRef}
        onClose={closeDialog}
        isOpen={isDialogOpen}
        isCentered
      >
        <AlertDialogContent width="90%">
          <AlertDialogHeader>Menti a foglalást?</AlertDialogHeader>
          <AlertDialogCloseButton />
          <AlertDialogBody>
            Az igenre nyomva elmenti a foglalást.
            {tableId && (
              <p>Asztal száma: {tableId}</p>
            )}
            {time && (
              <p>Foglalás időpontja: {time}</p>
            )}
            {duration && (
              <p>Foglalás időtartama: {duration} óra</p>
            )}
            {people && (
              <p>Emberek száma: {people} fő</p>
            )}
          </AlertDialogBody>
          <AlertDialogFooter>
            <Button ref={cancelRef} onClick={closeDialog}>
              Nem
            </Button>
            <form onSubmit={submitFunction}>
              <Button colorScheme='green' ml={3} type="submit" >
                Igen
              </Button>
            </form>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>

      {error && (
        <div style={{ background: 'red', color: 'white', padding: '10px', position: 'fixed', top: '0', left: '0', right: '0', zIndex: '9999', textAlign: 'center' }}>
          {error}
        </div>
      )}

    </>
  )
}