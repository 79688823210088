class RatingService{
  constructor(httpClient){
    this.httpClient = httpClient;
  }

  async getRatings(){
    try{
      const response = await this.httpClient.get('Ratings');
      return response.json();
    }catch(error){
      console.error(error)
    }
  }

  async createRating(rating){
    try{
      await this.httpClient.post('Ratings', rating);
    }catch(error){
      console.error(error)
    }
  }

  async updateRating(id, rating){
    try{
      await this.httpClient.put('Ratings/' + id, rating);
    }catch(error){
      console.error(error)
    }
  }

  async destroyRating(ratingId){
    try{
      await this.httpClient.destroy('Ratings/' + ratingId);
    }catch(error){
      console.error(error)
    }
  }
}

export default RatingService;