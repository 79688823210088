import React from 'react';
import {
    Stack,
    Link,
    Flex,
    Spacer,
    Button,
    IconButton,
    HStack,
} from '@chakra-ui/react';
import MobileDrawer from './mobile_drawer.js'
import { useNavigate } from "react-router-dom";
import { SignInButton } from '../profile/signInButton.jsx';
import { FaShoppingBasket } from 'react-icons/fa'

const Navbar = ({role, reservationId, isAuthenticated, accounts}) => {

    const navigate = useNavigate();
    const handleClick = (url) => {
        navigate(url);
    }

    let statButton = null;
    if(role===1){
        statButton = <Link onClick={() => handleClick("/statistics")} _hover={false} alignSelf={"center"}>Statisztika</Link>;
    }

    return (
	<>
        <Flex className="desktop-navbar sticky-nav" backgroundColor={'lightgray'} height={85} padding="0.5rem calc((100vw - 1000px) / 2)">
            <Stack spacing={8} direction='row' >
                <Link onClick={() => handleClick("/")} fontSize={24} _hover={false} alignSelf={"center"} fontWeight={'bold'} ml="10">Éttermünk</Link>
                <Link onClick={() => handleClick("/reservation")} _hover={false} alignSelf={"center"}>Foglalás</Link>
                <Link onClick={() => handleClick("/reservations")} _hover={false} alignSelf={"center"}>Foglalások</Link>
                <Link onClick={() => handleClick("/products")} _hover={false} alignSelf={"center"}>Étlap</Link>
                <Link onClick={() => handleClick("/ratings")} _hover={false} alignSelf={"center"}>Vélemények</Link>
                <Link onClick={() => handleClick("/gallery")} _hover={false} alignSelf={"center"}>Galéria</Link>
                {statButton}
            </Stack>
            <Spacer/>
            {reservationId!==0&&reservationId!==undefined ? <IconButton colorScheme='teal' p="2" m="4" alignSelf="center" onClick={() => handleClick("/basket?id=" + reservationId)}><FaShoppingBasket size="sm"/></IconButton> : null}
            {isAuthenticated&&accounts[0]!==undefined ? <Button fontSize={18} mr="10" colorScheme='teal' p="4" alignSelf="center" onClick={() => handleClick("/profile")}>{accounts[0].name}</Button> : <SignInButton ml="0" mr="10"/>}
        </Flex>
        <Flex className="mobile-navbar sticky-nav" backgroundColor={'lightgray'} height={85} padding="0.5rem calc((100vw - 1000px) / 2)" wrap="nowrap" justifyContent="space-between">
            <Link href='/' fontSize={30} _hover={false} alignSelf={"center"} fontWeight={'bold'} m="5" >Éttermünk</Link>
            <HStack>
                {reservationId!==0&&reservationId!==undefined ? <IconButton colorScheme='black' color='black' p="2" alignSelf="center" onClick={() => handleClick("/basket?id=" + reservationId)}><FaShoppingBasket size="sm"/></IconButton> : null}
                <MobileDrawer nameParam={accounts[0]===undefined?null:accounts[0].name} role={role}/>
            </HStack>
        </Flex>
	</>
    );
};

export default Navbar;
