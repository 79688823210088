import React from 'react';
import {
    Stack,
    Button,
    AlertDialog,
    AlertDialogBody,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogContent,
    AlertDialogOverlay,
    useDisclosure
} from '@chakra-ui/react'
import { MdDelete } from "react-icons/md"
import CategoryForm from './CategoryForm.js'
import FetchClient from '../../serviceclients/fetchclient.js';
import CategoryService from '../../services/categoryservice.js';
import { useAuth } from '../../providers/authProvider.jsx';


export default function ButtonGroup({category, onChange}){
  const authContext = useAuth();

    const deleteFunction = (categoryId) => {
        const categoryService = new CategoryService(new FetchClient(authContext));
        const destroyCategory = async () => {
          try{
            await categoryService.destroyCategory(categoryId);
          }catch(error){
            console.log(error);
          }
        }
        destroyCategory();
        onChange();
    };

    const DeleteConfirmation = () => {
      const { isOpen, onOpen, onClose } = useDisclosure()
      const cancelRef = React.useRef()
    
      return (
        <>
          <Button leftIcon={<MdDelete />} colorScheme='red' variant='outline' fontSize="sm" onClick={onOpen}>
            Törlés
          </Button>
    
          <AlertDialog
            isOpen={isOpen}
            leastDestructiveRef={cancelRef}
            onClose={onClose}
          >
            <AlertDialogOverlay>
              <AlertDialogContent width="90%">
                <AlertDialogHeader fontSize='lg' fontWeight='bold'>Kategória törlése</AlertDialogHeader>
    
                <AlertDialogBody>
                  Ha törlöd a kategóriát, az összes bennelévő termék is törlődik. Biztosan törölni szeretnéd? Ezt a műveletet nem tudod visszavonni.
                </AlertDialogBody>
    
                <AlertDialogFooter>
                  <Button ref={cancelRef} onClick={onClose}>
                    Mégse
                  </Button>
                  <Button colorScheme='red' onClick={() => deleteFunction(category.id)} ml={3}>
                    Törlés
                  </Button>
                </AlertDialogFooter>
              </AlertDialogContent>
            </AlertDialogOverlay>
          </AlertDialog>
        </>
      )
    }

    return (
      <Stack direction='row' spacing={4}>
        <CategoryForm isEdit={true} category={category} onChange={() => onChange()} />
        <DeleteConfirmation />
      </Stack>
    );
};
