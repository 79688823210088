import React from "react";
import { Button } from "@chakra-ui/react";
import { useMsal } from "@azure/msal-react";

/**
 * Renders a sign out button 
 */
export const SignOutButton = () => {
  const { instance } = useMsal();
  
  const handleLogout = (logoutType) => {
    instance.logoutRedirect({
        postLogoutRedirectUri: "/",
        mainWindowRedirectUri: "/",
      });
  };

  return (
    <Button fontSize={18} colorScheme='red' p="4" alignSelf="center" onClick={() => handleLogout()}>Kijelentkezés</Button>
  );
};
