import React, { useRef, useState, useEffect } from 'react';
import {
	Button,
	ButtonGroup,
	Center,
	Accordion,
	AccordionItem,
	AccordionButton,
	AccordionPanel,
	AccordionIcon,
	Flex,
	Heading,
	VStack
} from '@chakra-ui/react';
import { useNavigate } from "react-router-dom";
import { FaPlus } from 'react-icons/fa';
import ProductsForm from '../components/products/ProductsForm';

import ListItem from '../components/products/ProductsListItem'
import FetchClient from '../serviceclients/fetchclient';
import ProductService from '../services/productservice';
import NetworkError from '../components/connection/network_error';
import Spinner from '../components/connection/spinner';
import { useAuth } from '../providers/authProvider';

function timeout(delay) {
	return new Promise( res => setTimeout(res, delay) );
}

export default function Products({role, reservationId}) {
	const navigate = useNavigate();
	const handleClick = (url) => {
		navigate(url);
	}


	const [products, setProducts] = useState([]);
	const [isLoaded, setIsLoaded] = useState(false);

	const authContext = useAuth();

	const doFetch = async () => {
		setIsLoaded(false);
		await timeout(1000);
		const productService = new ProductService(new FetchClient(authContext));
		const fetchProducts = async ()=>{
			try{
				const prods = await productService.getProducts();
				setProducts(prods);
				setIsLoaded(true);
			}catch(error){
				console.log(error);
			}
		}
		fetchProducts();
	}

	// eslint-disable-next-line
	useEffect(() => { doFetch(); }, []);

	//Mágia : a menü feletti gombokat lenyomva a kategóriához görget
	const accordionRef = useRef(null);
	const scrollToAccordionItem = (index) => {
		if (accordionRef.current) {
			const accordionItems = accordionRef.current.children;
			if (index >= 0 && index < accordionItems.length) {
				accordionItems[index].scrollIntoView({ behavior: "smooth" });
				accordionItems[index].classList.toggle("open");

				const offset = document.getElementById("cats").clientHeight + 140;
				if(index < accordionItems.length-1) window.scrollBy(0, -offset);
			}
		}
	};

	/*Uj étel form
	Ez felel azárt, hogy látjuk vagy nem látjuk a form-ot
	 */
	const [isModalOpen, setIsModalOpen] = useState(false);
	const openModal = () => {
		setIsModalOpen(true);
	};
	const closeModal = () => {
		setIsModalOpen(false);
	};

	let newAndCatButtons = undefined;
	if(role===1){
		newAndCatButtons = (<ButtonGroup spacing="4"
										direction='center'
										alignItems="center"
										style={{ marginTop: "0.5%" }}
										size='sm'>
								<Button colorScheme='teal'
								leftIcon={ <FaPlus/>}
								onClick={openModal}>
								Új Termék
								</Button>
								<ProductsForm closeModal={closeModal} isModalOpen={isModalOpen} onChange={()=>doFetch()} />
								<Button colorScheme='teal' onClick={() => handleClick("/categories")}>Kategóriák</Button>
							</ButtonGroup>);
	}

	if (products === undefined) {
		return <NetworkError/>
	} else if (!isLoaded){
		return <Spinner/>
	} else {
		return(
			<>
				{/* Felső rész: Étlap, új termék, kategóriák */}
				<Center ml='5' mr='5'>
					<VStack>
					<Flex justifyContent="space-between" w='100%' className="sticky-products">
						<Heading textAlign = 'left'>Étlap</Heading>
						{newAndCatButtons}
					</Flex>
				

				{/* első center blokk: A menü fölötti buttongroup, a kategóriákat
			 reprezentáló gombok */}
				<div>
						<ButtonGroup spacing="4" w="100%"
												 direction='center'
												 alignItems="center"
												 className="sticky-products2"
												 id="cats">
							<Flex flexWrap="wrap" w='100%'>
								{products.map((item, index) => (
									<Button m="2" colorScheme='teal' size='sm' key={index} onClick={() => scrollToAccordionItem(index)}>
										{item.name}
									</Button>
								))}
							</Flex>
						</ButtonGroup>

					{/* ez meg a menü megvalósítása */}
					<div>
						<Accordion mb="20" allowMultiple ref={accordionRef} defaultIndex={products.map((_, index) => index)}>
							{products.map((category, index) => (
								<AccordionItem key={index} >
									<h2>
										<AccordionButton fontSize="3xl" >
											<AccordionIcon />
											{category.name}
										</AccordionButton>
									</h2>
									<AccordionPanel>
										{category.products.map((prod)=> (
											<ListItem key={prod.id} role={role} product={prod} onChange={()=>doFetch()} reservationId={reservationId} />
										))}
									</AccordionPanel>
								</AccordionItem>
							))}
						</Accordion>
					</div>
				</div>
				</VStack>
				</Center>
			</>
		);
	}


};
