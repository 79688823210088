import React from 'react';
import {
	VStack,
	StackDivider,
	Heading,
	Center,
	Text,
	Image
} from '@chakra-ui/react'
import restaurantImage1 from '../resources/images/home/restaurant1.png';
import wedding from '../resources/images/home/wedding.png';
import fancy_dish from '../resources/images/home/fancy_dish.png';
import bottle_of_wine from '../resources/images/home/bottle_of_wine.png';
import receptionist from '../resources/images/home/receptionist.png';
import crowd_of_people from '../resources/images/home/crowd_of_people.png';
import waitresses from '../resources/images/home/waitresses.png';
import { restaurantImage1URL } from '../resources/strings/home/urls';
import { fancyDishImageURL } from '../resources/strings/home/urls';
import { fancyWineImageURL } from '../resources/strings/home/urls';
import { fancyWeddingImageURL } from '../resources/strings/home/urls';
import { waitressesImageURL } from '../resources/strings/home/urls';
import { receptionistImageURL } from '../resources/strings/home/urls';
import { crowdOfPeopleURL } from '../resources/strings/home/urls';
import { introductionParagraph1 } from '../resources/strings/home/introduction';
import { introductionParagraph2 } from '../resources/strings/home/introduction';
import { introductionParagraph3 } from '../resources/strings/home/introduction';
import { introductionParagraph4 } from '../resources/strings/home/introduction';
import { introductionParagraph5 } from '../resources/strings/home/introduction';
import { introductionParagraph6 } from '../resources/strings/home/introduction';
import { introductionParagraph7 } from '../resources/strings/home/introduction';
import { introductionParagraph8 } from '../resources/strings/home/introduction';
import { introductionParagraph9 } from '../resources/strings/home/introduction';

const Home = () => {
	return (
		<Center>
			<VStack my="10" mx="5" fontSize='xl' width="4xl" divider={<StackDivider borderColor='gold' />} spacing={4}>
				<Heading size='2xl' textAlign="center" mt='7' mb='50' height='100'>
					Üdvözöljük Éttermünk Weboldalán
				</Heading>
				<Text>
					{introductionParagraph1}
				</Text>
				<Heading size='xl' noOfLines={1}>
					A Helyszín:
				</Heading>
				<Text>
					{introductionParagraph2}
				</Text>
				<Image boxShadow="lg" borderRadius="md" src={restaurantImage1} fallbackSrc={restaurantImage1URL} />
				<Heading size='xl' noOfLines={1}>
					Az Étlap:
				</Heading>
				<Text>
					{introductionParagraph3}
				</Text>
				<Image boxShadow="lg" borderRadius="md" src={fancy_dish} fallbackSrc={fancyDishImageURL} />
				<Heading size='xl' noOfLines={1}>
					Páratlan Borválaszték:
				</Heading>
				<Text>
					{introductionParagraph4}
				</Text>
				<Image boxShadow="lg" borderRadius="md" src={bottle_of_wine} fallbackSrc={fancyWineImageURL} />
				<Heading size='xl' noOfLines={1}>
					Különleges Események:
				</Heading>
				<Text>
					{introductionParagraph5}
				</Text>
				<Image boxShadow="lg" borderRadius="md" src={wedding} fallbackSrc={fancyWeddingImageURL} />
				<Heading size='xl' noOfLines={1}>
					Szolgáltatások:
				</Heading>
				<Text>
					{introductionParagraph6}
				</Text>
				<Image boxShadow="lg" borderRadius="md" src={waitresses} fallbackSrc={waitressesImageURL} />
				<Heading size='xl' noOfLines={1}>
					Kapcsolat:
				</Heading>
				<Text>
					{introductionParagraph7}
				</Text>
				<Image boxShadow="lg" borderRadius="md" src={receptionist} fallbackSrc={receptionistImageURL} />
				<Text>
					{introductionParagraph8}
				</Text>
				<Text>
					{introductionParagraph9}
				</Text>
				<Image boxShadow="lg" borderRadius="md" src={crowd_of_people} fallbackSrc={crowdOfPeopleURL} />
			</VStack>
		</Center>
	);
};

export default Home;