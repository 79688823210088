export const restaurantImage1URL = 'https://cdn.gencraft.com/prod/user/8e4e7d66-e0dd-4880-a5ac-76f8df71e874/e314583d-1a9e-425c-bab7-2aed448141cf/images/image1_0.jpg?Expires=1696884388&Signature=MTOE7cGJ4cBUGdGRLPJ5s5jHROG2QYxKeMJvRygd3Ucp5dB15xHGPlsAzbj~z~ptka5RQQFzcRTMtJD2GZJ4Ta4oTKTFXAZbbQjXv7NoKtowOXOz~LBQbNC~81WCV0bnpov5UGXMc7yG~CN1uTX7vy1AP9QUrUKcRx5H7gb~4zEM53IMo-ZIAMmIbbHF1rjb7RJIV6rbVtrRLvwWWhPaSZn0dwZR7U878kIT~L22NaCP1KgvhyGfhVIUape-7X4dn4DjMPWl7d4nkdt5RP3gNfkqBdyAw27X3FB4AVANwihAhSyhYSSweY0gcM23MJwNzakHjnROmG3JKR5AMm7jVg__&Key-Pair-Id=K3RDDB1TZ8BHT8';

export const fancyDishImageURL = 'https://cdn.gencraft.com/prod/user/8e4e7d66-e0dd-4880-a5ac-76f8df71e874/2806f7d8-c49f-4bdd-be7a-c64fe3b2ff06/images/image0_0.jpg?Expires=1696885263&Signature=lII6S4v2R6x-9c-9i2dAWVvNSaG7Kdzpr~mQLYtIHe7rE5wacK5D~iDAuUCUAeURc1QnE6uPXX7pOQhf9712pyKg40su5dK2tfwa6sNVW0byUwlauH7CShv5bSsPA19u2xYHx3dFdSDpYKahqqux9pHUE8h1Fqk11bt0D~u0R5NZusBFtqPoggI1N9MrXYn1SBr38WkqVvRyUgehLey7YfMoB8z73X2Cb8hdNXOSp3Ks9K5~3ZGCN3bDPotJNdSP6uC0MYDj-zHmkkLuW885SL1WHjICQWNTEAveG2sPyZUdwa6J0MqrNp9vgR7pescZ5TrgT5sbGTKAnJ5eUgyEVw__&Key-Pair-Id=K3RDDB1TZ8BHT8';

export const fancyWineImageURL = 'https://cdn.gencraft.com/prod/user/8e4e7d66-e0dd-4880-a5ac-76f8df71e874/39a4e635-7eb2-4083-ab84-1354e0260966/images/image1_0.jpg?Expires=1696885369&Signature=nrvsYYsSK-~pQKjHlXEtcKxN7mg7wJJmQ~fb03OT6Z1d3aTELsVQhokfqExOS67QD651qfB9GuB5Z1XUllR6tKKO0GS3TMDAaneAuhhlM5c4G1~ZYpqzKMPqTegFR3EWhg1JceACIOs2DPXkO3H~~8JPWSQQ-kFBRzXVGI4knKfH0L1pux6MwqyNFoNFognXr8vBx4nd2hx2pTruXadzIKkOXORx-rbHQcRvnX6vIB7q3MS0nX5Dv7aW2rnlfxORVkOZefcYpP~BECxX-qM-qPJK1tF7Wf88MGWFbPNK6y-AJizHUC~~bZNvZ1Udxu2KqTuomMAHd-oocXuKOlWxNw__&Key-Pair-Id=K3RDDB1TZ8BHT8';

export const fancyWeddingImageURL = 'https://cdn.gencraft.com/prod/user/8e4e7d66-e0dd-4880-a5ac-76f8df71e874/ea2c52d2-1220-4bff-a1be-c667420b64ae/images/image1_0.jpg?Expires=1696885489&Signature=nJd3lH6gJwLH9x4hzlRBwn~4D~W6m8lPYbX5nTs~y~6Yvmy9wcV6AJk9M5RHMlcCh4KUutqbmYV-IIzXxpyuKNQq1tHYrTe5AbMTt4InTV2EfuNvx1sjMeVArFcY4eGCraw9Ouu2pwgs730sAXSgUv3dAMUg8mWPFhHLNSBfKovPH5ZMKucz7RMfqyjJE~GFfG2AOZnBz6IT5H2cCtWIzGfZHH7Luk6dS9EfZfSibOvsR9vn9G-~sjH8KP2wp8zEivuy4~SxGP~nLp0SAs9YQGySbSlsTOzj0kdG5yhwzdpGxosLzZFK-O21CHs3CsZ0b1WGNBoplzxUeOL6OguYzQ__&Key-Pair-Id=K3RDDB1TZ8BHT8'

export const waitressesImageURL = 'https://cdn.gencraft.com/prod/user/8e4e7d66-e0dd-4880-a5ac-76f8df71e874/5f024586-7420-4d05-9959-595dedf08e92/images/image0_0.jpg?Expires=1696968541&Signature=UAfdWTbPgxj4M2KEk~wPqF4a6n707dXfcSuAgT-KkcitYnXtgy9RXqUdmv5ObHz-e9LnKLonqOR2UvYEl4-K-Fly1O576qOb5IN9MvdCCcMKv0kFqtt~S1hLmHT12FCHk6tb9uIXOkR1uUowdPaqlB7UaZzR2g83I55k4S9jgweS3kI8aRxtnandYKgoCQlpUnPwexWQvACwh3KHAvSxfbSpDgN4nGmTbjLzrT2-pXTtnnFvi6N9jnvVTyz5ZblOLa-MkMZtRd5wizOKIutnhWEFuYRcvHmXFKCyHbZA8PHj4HcRlk-E0kz6OXvf7a9r~DQNi-KO-93r2RnZl5JUQg__&Key-Pair-Id=K3RDDB1TZ8BHT8'

export const receptionistImageURL = 'https://cdn.gencraft.com/prod/user/8e4e7d66-e0dd-4880-a5ac-76f8df71e874/2a417a11-ead2-49cc-9bce-66c6714b260f/images/image1_0.jpg?Expires=1696968656&Signature=Mzu~i0HSdFjnNHF-OghIjeQ7o0SuSh72zd1LKlomBpxrHPKyCofEBAEGzNkjlEIOvaLVAos6DqwICq6oL7XCLjpr7s0i~ocN014yZDmeke-h4~1u1-pcWPILxZDi8wIJjcB4i9UOG66XB1hxZNL7PPaxOGAoKx65nHd8-mvyoeO7NBhUY3sdMMuqXha6V0aUY4IqkBmUV0YRzshrcYzmSYyAdkx8hzYj1F-1T7~DEgWR9YrrorF3PwpQn5wd567hnM25Lmz9p04hZPqwp7pMkthOMLNE5KXaDqLMSZkvDXthFyQk~uVAAMOsyCIAkmbnFxg8jgUr-JqTC-jABtxpQg__&Key-Pair-Id=K3RDDB1TZ8BHT8'

export const crowdOfPeopleURL = 'https://cdn.gencraft.com/prod/user/8e4e7d66-e0dd-4880-a5ac-76f8df71e874/5804ac75-0644-44b7-8fc1-59b9cbed2555/images/image1_0.jpg?Expires=1696968751&Signature=ru~dP8Ce-aGf~l6OLbZLmFM0epb~DkUj-anunAfRn95~2a3alKgWbSuleeq1yk0FKpWFdgrbACQPXmMgOFoIXa34iCvF9CtixBDuxXRElX3wD8ws2pwQWX4HYUP8gDnqvslw-DtBre5Ue477Rkzwk9STYr1BNGRaPofPI67sZKS1sjF-4rQ5IORIrOAf8ZxDE6~Vx6zz3VynOUiNJUM9uKy-CQTGT4YY05kjrdNeGWVN8waSDhgqqVmyDIyHuYJx1xndVuD4g9XNWoSP4d7si~8mNk-qCrR7ujJ7QeWUCk9g2UcIsDyEQ~avxRIu1fnXSaPlgWY4GbK6Rxhh6svmDQ__&Key-Pair-Id=K3RDDB1TZ8BHT8'