class ReservationService{
  constructor(httpClient){
    this.httpClient = httpClient;
  }

  async getReservations(){
    try{
      const response = await this.httpClient.get('Reservations');
      return response.json();
    }catch(error){
      console.error(error)
    }
  }

  async createReservation(reservation){
    try{
      const response = await this.httpClient.post('Reservations', reservation);
      return response.json();
    }catch(error){
      console.error(error)
    }
  }

  async updateReservation(reservation){
    try{
      await this.httpClient.put('Reservations/' + reservation.id, reservation);
    }catch(error){
      console.error(error)
    }
  }

  async destroyProduct(reservationId){
    try{
      await this.httpClient.destroy('Reservations/' + reservationId);
    }catch(error){
      console.error(error)
    }
  }
}

export default ReservationService;