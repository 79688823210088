import apiConfig from '../config/apiConfig';

const urlBase = apiConfig.webApi;

class FetchClient {
    constructor(authContext) {
        this.authContext = authContext;
    }

    async get(url) {
        let headers = getHeaders(this.authContext);
        return await fetch(urlBase + url, { headers });
    }

    async post(url, body) {
        let headers = getHeaders(this.authContext);
        return await fetch(urlBase + url, {
          method: 'POST',
          body: JSON.stringify(body),
          headers,
        });
    }

    async put(url, body){
        let headers = getHeaders(this.authContext);
        return await fetch(urlBase + url, {
            method: 'PUT',
            body: JSON.stringify(body),
            headers: headers
        });
    }

    async destroy(url){
        let headers = getHeaders(this.authContext);
        return await fetch(urlBase + url, {
            method: 'DELETE',
            headers: headers
        });
    }

    async postImage(url, body){
        let headers = getImageHeaders(this.authContext);
        return await fetch(urlBase + url, {
          method: 'POST',
          body: body,
          headers,
        });
    }

    async putImage(url, body){
        let headers = getImageHeaders(this.authContext);
        return await fetch(urlBase + url, {
            method: 'PUT',
            body: body,
            headers,
          });
    }
}

function getHeaders(authContext) {
    let headers = new Headers();
    headers.append('Content-Type', 'application/json');
  
    if (authContext && authContext.token && authContext.token.accessToken) {
        headers.append('Authorization', `Bearer ${authContext.token.accessToken}`);
    }
  
    headers.append('Access-Control-Allow-Origin', '*');
    headers.append('Access-Control-Allow-Headers', 'Origin, X-Requested-With, Content-Type, Accept');
    headers.append('Access-Control-Allow-Methods', 'GET, POST, PUT, DELETE');
    return headers;
}

function getImageHeaders(authContext) {
    let headers = new Headers();

    if (authContext && authContext.token && authContext.token.accessToken) {
        headers.append('Authorization', `Bearer ${authContext.token.accessToken}`);
    }
  
    headers.append('Access-Control-Allow-Origin', '*');
    headers.append('Access-Control-Allow-Headers', 'Origin, X-Requested-With, Accept, Authorization');
    headers.append('Access-Control-Allow-Methods', 'GET, POST, PUT, DELETE');

    return headers;
}

export default FetchClient;